<template>
    <div class="modal modal-fullscreen subscribe-modal" tabindex="-1" role="dialog"
         v-bind:class="{ 'modal-visible': visible }">
        <heading class="centered"></heading>
        <div class="modal-dialog is-visible">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="close">
                        <span>×</span></button>
                    <h4 class="modal-title modal-title-default">
                        Upgrade my account
                    </h4>
                </div>
                <div class="modal-body">
                    <a href="javascript:;" v-on:click="goBack" title="Go back" class="previous-button"
                       v-if="showBackButton">
                        <svg class="svg-icon" viewBox="0 0 20 20">
                            <path
                                d="M11.739,13.962c-0.087,0.086-0.199,0.131-0.312,0.131c-0.112,0-0.226-0.045-0.312-0.131l-3.738-3.736c-0.173-0.173-0.173-0.454,0-0.626l3.559-3.562c0.173-0.175,0.454-0.173,0.626,0c0.173,0.172,0.173,0.451,0,0.624l-3.248,3.25l3.425,3.426C11.911,13.511,11.911,13.789,11.739,13.962 M18.406,10c0,4.644-3.763,8.406-8.406,8.406S1.594,14.644,1.594,10S5.356,1.594,10,1.594S18.406,5.356,18.406,10 M17.521,10c0-4.148-3.373-7.521-7.521-7.521c-4.148,0-7.521,3.374-7.521,7.521c0,4.148,3.374,7.521,7.521,7.521C14.147,17.521,17.521,14.148,17.521,10"></path>
                        </svg>
                        <span>Go back</span>
                    </a>
                    <div class="tab-content">
                        <router-view :plans="plans" :billing="billing"></router-view>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Heading from '../heading'

export default {
    components: {Heading},
    data: function () {
        return {
            visible: true,
            showBackButton: false,
            previousUrl: '/dashboard',
            plans: null,
            billing: {
                company_name: null,
                address: null,
                zipcode: null,
                city: null,
                country: null,
                phone_number: null
            },
            account: null
        }
    },
    created: function () {
        this.$http.get('internal/payment/plan').then(
            (xhr) => {
                if (xhr.data) {
                    this.plans = xhr.data
                }
            },
            (xhr) => {
            }
        )
        this.$http.get('internal/payment/billing').then(
            (xhr) => {
                if (xhr.data) {
                    this.billing = xhr.data
                }
            },
            (xhr) => {
            }
        )
    },
    methods: {
        close: function () {
            this.visible = false
            this.$router.push({path: this.previousUrl})
        },
        goBack: function () {
            history.back()
        }
    },
    beforeRouteEnter: function (to, from, next) {
        document.body.classList.add('has-payment-modal')
        next()
    },
    beforeDestroy: function () {
        document.body.classList.remove('has-payment-modal')
    }
}
</script>

<style>
.subscribe-modal .modal-body {
    padding: 0px;
}

.subscribe-modal .modal-body .tab-content > div {
    text-align: center;
}
</style>
