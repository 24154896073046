<template>
    <div class="register-testimonials">
        <ul class="features">
            <li><i class="fa fa-check"/> 100 free credits per month</li>
            <li><i class="fa fa-check"/> Up to 98% accuracy</li>
            <li><i class="fa fa-check"/> No credit card required</li>
            <li><i class="fa fa-check"/> Free access to SDK & Plugins</li>
        </ul>
        <ul class="testimonials">
            <li>
                <div class="content">It was like a magic to detect many spam accounts that saved us a lot.</div>
                <span class="person">
                    <img src="/img/testimonials/donovan.jpg" class="rounded-circle">
                    <span>
                        <div>July Donovan</div>
                        <div>CEO at Altair</div>
                    </span>
                </span>
            </li>
            <li>
                <div class="content">
                    I was able to clean my database out of spam users, easier to go over the real data.
                    If you want to easily find and flag fraud attempts, this is the right tool.
                </div>
                <span class="person">
                    <img src="/img/testimonials/wagner.jpg" class="rounded-circle">
                    <span>
                        <div>Jonas Wagner</div>
                        <div>CTO & CO-founder at Bitzfree</div>
                    </span>
                </span>
            </li>
            <li>
                <div class="content">
                    FraudRadar helped us to reduce the number of churns and chargebacks,
                    thanks for their great support.
                </div>
                <span class="person">
                    <img src="/img/testimonials/dubois.jpg" class="rounded-circle">
                    <span>
                        <div>Tanner Dubois</div>
                        <div>Founder at EvoTRio</div>
                    </span>
                </span>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'register-testimonials'
}
</script>

<style scoped lang="scss">
.register-testimonials {
    display: none;
    color: #222;
    width: 300px;

    .fa-check {
        color: green;
    }

    .features {
        font-size: 16px;

        li {
            list-style-type: none;
            margin-bottom: 1rem;
        }
    }

    .testimonials {
        > li {
            border-top: 1px solid #d3d3d3;
            margin-top: 20px;
            padding: 20px 0 10px;
        }

        .content {
            margin-bottom: 15px;
            text-align: justify;
        }

        img {
            float: left;
            margin-right: 15px;
        }
    }
}
</style>
