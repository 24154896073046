<template>
    <div v-bind:class="{'is-yearly': isYearlySelected}">
        <h4 class="lead-title fade-in one">Here is our plans</h4>
        <p class="lead-price fade-in two">
            You have several options to choose from. <br/>
            To learn details and compare each pricing plan, please visit <a href="//fraudradar.io/pricing"
                                                                            target="_blank">FraudRadar.io website!</a>
        </p>
        <ul class="switch-time fade-in three" v-show="false">
            <li class="monthly" v-bind:class="{'active': !isYearlySelected}">
                <a v-on:click="changeMonthlyYearly(false)">Monthly</a>
            </li>
            <li class="yearly" v-bind:class="{'active': isYearlySelected}">
                <a v-on:click="changeMonthlyYearly(true)">Yearly <span>with a discount!</span></a>
            </li>
        </ul>
        <template v-if="plans">
            <ul class="plans fade-in one">
                <li v-for="plan in plans" v-show="plan.yearly == isYearlySelected" :key="plan.id">
                    <h4>{{ plan.display.replace('Monthly', '') }}</h4>
                    <p>{{ plan.metadata.credits | currency('',',',0) }} credits /month</p>
                    <div class="plan-feature">
                        <strong>
                            <svg class="svg-icon" viewBox="0 0 20 20">
                                <path
                                    d="M15.573,11.624c0.568-0.478,0.947-1.219,0.947-2.019c0-1.37-1.108-2.569-2.371-2.569s-2.371,1.2-2.371,2.569c0,0.8,0.379,1.542,0.946,2.019c-0.253,0.089-0.496,0.2-0.728,0.332c-0.743-0.898-1.745-1.573-2.891-1.911c0.877-0.61,1.486-1.666,1.486-2.812c0-1.79-1.479-3.359-3.162-3.359S4.269,5.443,4.269,7.233c0,1.146,0.608,2.202,1.486,2.812c-2.454,0.725-4.252,2.998-4.252,5.685c0,0.218,0.178,0.396,0.395,0.396h16.203c0.218,0,0.396-0.178,0.396-0.396C18.497,13.831,17.273,12.216,15.573,11.624 M12.568,9.605c0-0.822,0.689-1.779,1.581-1.779s1.58,0.957,1.58,1.779s-0.688,1.779-1.58,1.779S12.568,10.427,12.568,9.605 M5.06,7.233c0-1.213,1.014-2.569,2.371-2.569c1.358,0,2.371,1.355,2.371,2.569S8.789,9.802,7.431,9.802C6.073,9.802,5.06,8.447,5.06,7.233 M2.309,15.335c0.202-2.649,2.423-4.742,5.122-4.742s4.921,2.093,5.122,4.742H2.309z M13.346,15.335c-0.067-0.997-0.382-1.928-0.882-2.732c0.502-0.271,1.075-0.429,1.686-0.429c1.828,0,3.338,1.385,3.535,3.161H13.346z"></path>
                            </svg>
                            API usage included
                        </strong><br/>
                        <strong>
                            Add-ons & SDKs
                        </strong><br/>
                        <strong>{{ plan.metadata.validation_rules }} Validation Rules</strong>
                        <strong>{{ plan.metadata.grouping_rules }} Complex Rules</strong>
                    </div>
                    <div class="price">
                        <sup>$</sup>
                        <span>{{ (plan.amount / 100) | currency('',',',2) }} / month</span>
                    </div>
                    <div class="price-yearly" v-if="false">
                        <sup>$</sup>
                        <span>{{ (plan.amount / 100) | currency('',',',2) }} / month</span>
                        <div class="save">
                            <span>You save {{ getMonthlyCostDiff(plan.id) | currency('',',',2) }}</span>
                        </div>
                    </div>
                    <router-link :to="{'path': '/subscribe/' + plan.id + '/pay'}" title="Next" class="button">
                        Select this plan
                    </router-link>
                </li>
            </ul>
            <div class="clear"></div>
            <p class="need-more fade-in three">Need more? <a href="mailto:support@fraudradar.io"
                                                             title="Contact me and discuss your needs">Contact me</a>
                for custom pricing!</p>
        </template>
        <template v-else>
            <div class="spinner spinner-dark spinner-middle">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
            </div>
        </template>
    </div>
</template>

<script>
import Account from '../../store/account'

export default {
    data: function () {
        return {
            isYearlySelected: false,
            account: Account,
            loading: false
        }
    },
    props: ['plans'],
    created: function () {
    },
    methods: {
        changeMonthlyYearly: function (isYearly) {
            this.isYearlySelected = isYearly
        },
        getMonthlyCostDiff: function (planId) {
            let monthly = planId.replace('yearly', 'monthly')
            let yearly = planId.replace('monthly', 'yearly')

            let monthlyPlan = this.getPlan(monthly)
            let yearlyPlan = this.getPlan(yearly)

            return (monthlyPlan.price - yearlyPlan.price) * 12
        },
        getPlan: function (planId) {
            return this.plans.find((plan) => {
                return plan.id === planId
            })
        }
    }
}
</script>

<style scoped>
.plans .button {
    border-radius: 0 0 3px 3px;
    position: absolute;
    bottom: 0;
    left: -1px;
    right: -1px;
    margin-bottom: -1px;
}

.credit-rollover {
    color: #60be53;
}

.credit-rollover svg path {
    fill: #60be53;
}

.modal-fullscreen .plans li {
    height: 320px;
}

.modal-fullscreen .is-yearly .plans li {
    height: 345px;
}

.plans li.prepaid {
    width: 96.5%;
    height: auto;
    margin-top: 40px;
    clear: both;
}

.modal-fullscreen .is-yearly .plans li.prepaid {
    height: auto;
}

.plans .prepaid .button {
    float: right;
    display: inline-block;
    width: auto;
    position: relative;
}

.plans h4 {
    font-size: 19px;
    margin-bottom: 15px;
}

.modal-fullscreen .plans li .plan-feature {
    margin-top: 15px !important
}

.modal-fullscreen .plans li.prepaid .plan-feature.team-members,
.modal-fullscreen .plans li.prepaid .price {
    margin-top: 0 !important;
}

.modal-fullscreen .plans li.prepaid .price select,
.modal-fullscreen .plans li.prepaid a.button {
    width: 250px;
    border-radius: 3px;
}

.col-2 {
    width: 25%;
    float: left;
    text-align: left;
}

.col-6 {
    width: 50%;
    float: right;
    text-align: right;
}

.modal-fullscreen .plan-feature strong {
    font-weight: normal;
    border-top: 1px solid #e8e8e8;
    display: inline-block;
    margin: 0;
    padding: 6px;
    width: 100%;
}

.modal-fullscreen .plan-feature strong.bold {
    font-weight: bold;
}

.no-top-border {
    border-top: none !important;
}
</style>
