import common from './common.js'
import auth from './auth.js'
import menu from './menu.js'
import settings from './settings.js'
import whitelist from './whitelist.js'
import validationRule from './validation-rule.js'

export default {
    'common': common,
    'auth': auth,
    'menu': menu,
    'settings': settings,
    'whitelist': whitelist,
    'valdiationRule': validationRule
}
