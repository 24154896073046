<template>
    <div class="settings-modal">
        <h3>Billing Details</h3>

        <loading @show="loading"></loading>
        <form v-on:submit.prevent="update" ref="form" v-if="billing">
            <div>
                <label>{{ $t('settings.company_name') }}</label>
                <input type="text" v-model="billing.company_name" required/>
            </div>
            <div>
                <label>{{ $t('settings.address') }}</label>
                <input type="text" v-model="billing.address" v-on:keyup="resetBtnState" required/>
            </div>
            <div>
                <label>{{ $t('settings.zipcode') }}</label>
                <input type="text" maxlength="10" v-model="billing.zipcode" v-on:keyup="resetBtnState" required/>
            </div>
            <div>
                <label>{{ $t('settings.city') }}</label>
                <input type="text" maxlength="10" v-model="billing.city" v-on:keyup="resetBtnState" required/>
            </div>
            <div>
                <label>{{ $t('settings.country') }}</label>
                <input type="text" v-model="billing.country" v-on:keyup="resetBtnState" required/>
            </div>
            <div>
                <label>{{ $t('settings.phone_number') }}</label>
                <input type="text" maxlength="20" v-model="billing.phone_number" v-on:keyup="resetBtnState" required/>
            </div>
            <div class="button-container">
                <span style="margin-top: 5px">
                    <input type="submit" v-bind:value="$t('common.update')" class="btn" v-if="!saved"/>
                    <input type="button" v-bind:value="$t('common.saved')" class="btn" disabled v-if="saved">
                </span>
            </div>
        </form>
    </div>
</template>

<script>
import Tooltips from './../misc/tooltips'

export default {
    components: {},
    data: function () {
        return {
            saved: false,
            billing: {
                company_name: null,
                address: null,
                zipcode: null,
                city: null,
                country: null,
                phone_number: null
            },
            loading: false
        }
    },
    created: function () {
        this.loading = true
        this.$http.get('internal/payment/billing').then(
            (xhr) => {
                if (xhr.data) {
                    this.billing = xhr.data
                }
                this.loading = false
            },
            (xhr) => {
                this.loading = false
            }
        )
    },
    methods: {
        update: function () {
            this.saved = true
            let form = Tooltips(this.$refs.form)
            form.clear()

            this.$http.post('internal/payment/billing', this.billing).then(
                (xhr) => {
                    this.$alert.info({message: this.$i18n.t('settings.successfully_updated_account')})
                },
                (xhr) => {
                    this.saved = false
                    form.error({btgAddress: [this.$i18n.t('settings.' + xhr.data.error)]})
                }
            )
        },
        resetBtnState: function () {
            this.saved = false
        }
    }
}
</script>
<style>

</style>
