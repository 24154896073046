<template>
    <div class="credits u-pull-left margin-top-12" v-if="account.model" v-bind:class="{'credits-low': isCreditsLow()}">
        <template v-if="account.model.credits.remains == 0">
            <div class="credits-progress-wrapper"
                 v-bind:class="{'not-verified': account.model && !account.model.verified}">
                <div class="credits-progress">
                    <div style="width:100%"></div>
                </div>
                <span v-show="account.model && account.model.verified">{{ $t('common.no_credits') }}</span>
                <span v-show="account.model && !account.model.verified"
                      class="text-red">{{ $t('auth.not_verified') }}</span>
            </div>
        </template>
        <template v-else>
            <div class="credits-progress-wrapper">
                <div class="credits-progress">
                    <div v-bind:style="{'width': getPercentage() + '%'}"></div>
                </div>
                <span>Requests: {{ getRequests() }}/{{ account.model.credits.total }}</span>
            </div>
        </template>
        <div class="credits-upgrade"
             v-if="account.model && (!account.model.stripe_plan_id || (account.model.credits.remains / account.model.credits.total < 0.1))">
            <router-link to="/subscribe/plans" title="You rock!" class="btn button-success cta" active-class="active"
                         v-show="account.model && account.model.verified">
                {{ $t('common.get_more_credits') }}
            </router-link>
        </div>
    </div>
</template>

<script>
import Account from './../../store/account'

export default {
    props: {
        showButton: {
            type: Boolean,
            default: true
        }
    },
    data: function () {
        return {
            account: Account
        }
    },
    methods: {
        getRequests: function () {
            if (this.account.model.credits) {
                return (this.account.model.credits.total - this.account.model.credits.remains)
            }

            return 0
        },
        getPercentage: function () {
            if (this.account.model.credits.total > 0) {
                return ((this.getRequests() / this.account.model.credits.total) * 100).toFixed(2)
            } else if (this.account.model.credits.remains === 0) {
                return 100
            } else {
                return 0
            }
        },
        isCreditsLow: function () {
            return (this.getPercentage() > 80)
        }
    }
}
</script>

<style>
.credits {
    font-weight: normal;
    padding-left: 10px;
    width: 340px;
    margin-top: 3px;
    display: flex;
}

.credits > .credits-progress-wrapper {
    width: 60%;
    margin-top: 12px;
    margin-right: 10px;
}

.credits > .credits-progress-wrapper.not-verified {
    width: 100%;
}

.credits .credits-progress, .credits .credits-progress div {
    height: 3px;
    width: 100%;
    border-radius: 20px;
    background: #E8E5E2;
}

.credits .credits-progress div {
    background: #5FBE52;
}

.credits-low .credits-progress div {
    background: #D26A6A;
}

.credits .credits-upgrade {
    position: inherit;
    padding: 0px;
    width: auto;
    top: 0;
    left: 220px;
    height: 60px;
    opacity: 1;
    -webkit-transition: all .15s ease-in;
    -moz-transition: all .15s ease-in;
    -o-transition: all .15s ease-in;
    transition: all .15s ease-in;
    -webkit-transform: scale(0.7);
    -moz-transform: scale(0.7);
    -o-transform: scale(0.7);
    -ms-transform: scale(0.7);
    transform: scale(1);
}

.credits:hover .credits-upgrade {
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.credits-upgrade a {
    font-size: 10px;
    letter-spacing: 0.5px;
    width: 100%;
    position: relative;
    text-decoration: none;
    font-weight: bold;
    padding: 8px 16px;
    border-radius: 3px;
    display: block;
    margin-top: 4px;
}

.credits-upgrade a:hover {
    color: #fff;
}

.credits-progress-wrapper .text-red {
    font-size: 12px;
}
</style>
