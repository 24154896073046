export default {
    'account': 'Account',
    'your_plan': 'Your Plan',
    'credit_card': 'Credit Card',
    'email': 'Your email',
    'old_password': 'Old Password',
    'password': 'Password',
    'repassword': 'Re-password',
    'old_password_placeholder': 'Type your current password to change it',
    'password_placeholder': 'Type a new password',
    'repassword_placeholder': 'Re-enter new password',
    'delete': 'Delete',
    'resend': 'Resend Verification Link',
    'successfully_updated_account': 'Successfully updated your account!',
    'old_password_empty': 'To update your password, you need to fill your current password!',
    'old_password_invalid': 'Your current password is incorrect, please retry to enter it!',
    'new_password_empty': 'New password cant be empty',
    'passwords_dont_match': 'Passwords does not match, please reenter your new password!',
    'failed_update_password': 'Failed to update password',
    'failed_updated': 'Failed to update your account!',
    'successfully_deleted': 'Your account is successfully deleted, we are sorry to see you go!',
    'failed_deleted': 'There was a problem during deletion of your account please contact support!',
    // Billing section is here
    'billing': 'Billing',
    'company_name': 'Company Name',
    'address': 'Address',
    'zipcode': 'Postal Code',
    'city': 'City',
    'country': 'Country',
    'phone_number': 'Phone Number',
    'successfully_updated_billing': 'Successfully updated your billing details!',
    // API Key section is here
    'api_key': 'API Key',
    'request_api_key': 'Request an API Key',
    'renew_api_key': 'Renew API Key',
    'api_key_copied': 'API Key copied to clipboard.'
}
