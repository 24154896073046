<template>
    <div class="page-body settings-page">
        <header>
            <span class="u-pull-left">
                <h3 class="breadcrumb">Settings</h3>
            </span>
        </header>
        <div class="header">
            <ul class="nav nav-tabs">
                <router-link to="/settings/account" tag="li" role="presentation" active-class="active">
                    <a>
                        <i class="fa fa-user fa-2x"></i>
                        <div>{{ $t('settings.account') }}</div>
                    </a>
                </router-link>
                <router-link to="/settings/billing" tag="li" role="presentation" active-class="active">
                    <a>
                        <i class="fa fa-vcard-o fa-2x"></i>
                        <div>{{ $t('settings.billing') }}</div>
                    </a>
                </router-link>
                <router-link to="/settings/credit-card" tag="li" role="presentation" active-class="active">
                    <a>
                        <i class="fa fa-credit-card fa-2x"></i>
                        <div>{{ $t('settings.credit_card') }}</div>
                    </a>
                </router-link>
            </ul>
        </div>
        <div class="modal-body">
            <div class="tab-content">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
import './../../assets/css/modal.css'
import Modal from '../misc/modal.mixin'

export default {
    data() {
        return {
            previousUrl: '/dashboard'
        }
    },
    mixins: [Modal],
    route: {
        activate: function (transition) {
            if (transition.from && transition.from.path && transition.from.path.indexOf('settings') === -1) {
                this.previousUrl = transition.from.path
            }

            transition.next()
        }
    },
    events: {
        modalClose: function () {
            this.close()
        }
    }
}
</script>

<style>
.settings-page .header ul li a:first-child {
    border-radius: 5px 0 0 0;
    color: grey;
}

.settings-page .header ul li a {
    text-align: center;
}

.settings-page .modal-content {
    height: 100%;
}

.settings-page .tab-content h3 {
    font-size: 25px;
    margin-bottom: 5px;
    color: #A09D99;
}

.settings-page .tab-content h5 {
    font-size: 15px;
    margin-bottom: 10px;
    color: #A09D99;
}

.settings-page .tab-content form > div:not(.button-container) {
    border-bottom: 1px solid #EAEAEA;
    height: 44px;
    padding-top: 2px;
}

.settings-page .btn {
    background: #0ac4ac;
    color: #fff;
    border-radius: 3px;
    font-size: 12px;
    width: auto;
    padding: 0 20px;
    height: 37px;
    margin: 20px 0 0 0;
    float: left;
}

.settings-page form label {
    display: inline-block;
    width: 140px;
    text-align: left;
}

.settings-page form input[type=text],
.settings-page form input[type=password] {
    width: 400px;
}

.settings-page .settings-modal {
    width: 550px;
    margin: 0 auto;
}

.settings-page .settings-modal form {
    float: left;
}

.settings-page .settings-modal h3 {
    margin-bottom: 25px;
}
</style>
