<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>


<script>
import './assets/css/body.css'

import {event} from './event.js'
import Account from './store/account'

import Vue from 'vue'

export default {
    name: 'app',
    data: function () {
        return {}
    },
    created: function () {
        let session = window.storage.getItem('session')
        if (session) {
            Vue.http.options.headers = {'auth-token': session}
        }
        let self = this
        Vue.http.interceptors.push(function (request, next) {
            next((response) => {
                window.storage.setItem('time', new Date().getTime(), true)
                if (response.status === 401) {
                    event.emit('set-session', null, false, '/auth/login?loggedout=1&result=' + (response.data.error || ''))
                } else if (response.status === 402 && response.data && response.data.redirect) {
                    self.$route.push(response.data.redirect)
                }
            })
        })

        if (['auth-token', 'validate-account'].indexOf(this.$route.name) === -1 && session !== null) {
            event.emit('set-session', session)
        }

        event.on('set-session', (session, noRedirect, redirectUrl) => {
            if (session === null) {
                if (window.storage.getItem('session') !== null) {
                    window.storage.removeItem('session')
                    redirectUrl = redirectUrl || '/auth/login?loggedout=1'
                } else {
                    redirectUrl = redirectUrl || '/auth/login'
                }
                if (!noRedirect) {
                    this.$router.push(redirectUrl)
                }
            } else {
                window.storage.setItem('session', session)
                Vue.http.options.headers = {'auth-token': session}

                // Initialize environment
                Vue.http.get('internal/account/data').then((xhr) => {
                    Account.setModel(xhr.data)

                    return xhr
                }, (xhr) => {
                    event.emit('set-session', null)

                    return xhr
                })
            }
        })
    },
    methods: {}
}
</script>

<style>
#app {
    width: 100%;
    height: 100%;
}
</style>
