<template>
    <div id="app-wrapper">
        <vue-alert></vue-alert>
        <app-menu v-bind:class="{'menu-visible': menuVisible}" @click="menuVisible = false"></app-menu>
        <div id="app-container">
            <heading></heading>
            <div id="app-body">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
/* global window */
import '../../assets/css/skeleton.css'
import '../../assets/css/body.css'
import '../../assets/css/app.css'
import '../../assets/css/master-detail.css'

import {event} from '../../event.js'
import Heading from '../heading.vue'
// import Tour from '../misc/tour'
import AppMenu from '../menu.vue'
import Application from '../../store/application'
import Account from '../../store/account'

export default {
    components: {
        Heading, AppMenu
    },
    data: function () {
        return {
            listeners: {},
            isReconnecting: false,
            menuVisible: false,
            application: Application,
            account: Account
        }
    },
    created: function () {
        this.$watch(() => Account.model, (model) => {
            if (Account.model) {
                this.accountModelReady()
            }
        })
        if (Account.model) {
            this.accountModelReady()
        }

        let session = window.storage.getItem('session')
        if (['auth-token', 'validate-account'].indexOf(this.$route.name) === -1 && session !== null) {
            event.emit('set-session', session)
        }
    },
    mounted: function () {
        this.$alert.setDefault({
            duration: 15000
        })
    },
    methods: {
        accountModelReady: function () {
            // Prepare Model

            if (!this.isMobile()) {
                // Tour.startTour(Account, this)
            }
        },
        toggleMenu: function () {
            this.menuVisible = !this.menuVisible
        },
        isMobile: function () {
            return /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(navigator.userAgent) ||
                /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(navigator.userAgent)
        }
    }
}
</script>

<style>
.button.activate-button {
    width: 88%;
    margin: 0 auto;
    padding: 0 10px;
    font-size: 0.8em;
    height: 30px;
    line-height: 30px;
    margin-top: 6px;
}

#app-wrapper {
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    min-height: 100%;
    height: 100%;
}

#app-container {
    width: calc(100% - 240px);
    height: 100%;
    background: #f9f9f9;
}

#app-container #app-body {
    height: calc(100% - 50px);
    padding: 30px 30px 0px 30px;
}

@media (max-width: 1500px) {
    #app-wrapper {
        min-height: 900px;
    }
}

@media (max-width: 1199px) {
    #app-wrapper .hide-mobile {
        display: none;
    }

    #app-wrapper .collapse.in {
        display: block !important;
    }
}

@media (max-width: 991px) {
    #app-wrapper {
        top: 50px;
        min-height: 980px;
    }

    #app-container {
        display: block;
        width: 100%;
        border: none;
    }

    #app-wrapper .right-space,
    #app-wrapper .left-space {
        display: none;
    }

    #app-wrapper #wallet-container {
        width: 200px;
        position: fixed;
        background: white;
        right: 3px;
        top: 50px;
        margin: 3px 0px;
        height: 2px;
        border: 1px solid #8686a0;
    }

    #app-navigation {
        display: none;
    }

    #app-navigation.menu-visible {
        display: block;
        position: fixed;
    }
}
</style>
