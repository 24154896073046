<template>
    <div>
        <h1 class="lost-title">{{ $t('auth.lost_password') }}</h1>
        <template v-if="! sent">
            <form v-on:submit.prevent="submit">
                <div>
                    <i class="fa fa-user fa-2x"></i>
                    <input type="email" v-model="email" v-bind:placeholder="$t('auth.your_email')" required/>
                </div>
                <div class="non-human-panel">
                    <div class="u-pull-right">
                        <div ref="recaptchaEl" name="g-recaptcha" class="g-recaptcha"
                             data-sitekey="6LcDz9MUAAAAACq3gZiC2Ju5_G7revDL2fdkJp1T" required></div>
                    </div>
                </div>
                <template v-if="error">
                    <p class="error">{{ error }}</p>
                </template>
                <div>
                    <input type="submit" class="btn cta button-success" v-bind:value="$t('auth.retrieve_password')"/>
                </div>
            </form>
        </template>
        <template v-if="sent">
            <p class="success">{{ $t('auth.reset_link_sent') }}</p>
        </template>

        <div class="option-panel">
            <router-link to="/auth/login" class="u-center">{{ $t('auth.back_to_login') }}</router-link>
        </div>
    </div>
</template>

<script>
/* global grecaptcha */
import Tooltips from './../misc/tooltips'

export default {
    data: function () {
        return {
            email: window.storage.getItem('login-email', true),
            sent: false,
            error: false,
            randomImageId: Math.floor((Math.random() * 6) + 1)
        }
    },
    mounted: function () {
        if (this.isProduction()) {
            grecaptcha.render(this.$refs.recaptchaEl)
        }
    },
    methods: {
        submit: function () {
            let tooltips = Tooltips(document.querySelector('form'))
            tooltips.clear()
            let captchaText = null
            if (this.isProduction()) {
                let recaptchaResponse = document.getElementsByName('g-recaptcha-response')[0]
                captchaText = recaptchaResponse.value
                if (captchaText.length === 0) {
                    tooltips.error({'g-recaptcha': ['Please fill recaptcha!']})
                    return
                }
            }

            this.$http.post('internal/auth/lost', {
                email: this.email,
                captcha: captchaText
            }).then(
                (xhr) => {
                    this.sent = true
                },
                (response) => {
                    tooltips.error(response.data)
                }
            )
        },
        isProduction: function () {
            return window.location.hostname.indexOf('bitzfree.com') !== -1 || window.location.hostname.indexOf('elasticbeanstalk.com') !== -1
        }
    }
}
</script>

<style>
a.button-gmail {
    color: #fff !important;
    width: 100%;
    height: 45px;
    display: block;
    font-size: 11px !important;
    padding: 4px;
    letter-spacing: 1.5px;
}

h1.lost-title {
    margin-bottom: 20px !important;
}

.u-center {
    text-align: center;
    width: 100%;
    display: block;
}

</style>
