<template>
    <div class="page-body rules-page">
        <header>
            <span class="u-pull-left">
                <h3 class="breadcrumb">Validation Rules</h3>
            </span>
        </header>

        <ul>
            <li class="row header">
                <div clasS="col-xs-1"></div>
                <div clasS="col-xs-5 col-sm-3 col-md-3 col-lg-3 col-xlg-3">For Rule</div>
                <div clasS="col-xs-2">When Value is</div>
                <div clasS="col-xs-3 col-md-2">Do Action</div>
                <div clasS="col-xs-1 col-md-2"></div>
            </li>
            <li v-for="rule in rules" class="row" :key="rule.id">
                <div clasS="col-xs-1">
                    <a @click="addComplexRule(rule)" title="Add a complex rule">
                        <i class="fa fa-plus"></i>
                    </a>
                </div>
                <div clasS="col-xs-5 col-sm-3 col-md-3 col-lg-3 col-xlg-3">
                    <v-select :options="ruleTypeOptions" v-model="rule.rule_id" :reduce="item => item.id"
                              label="display" @input="ruleChanged(rule, true)" :clearable="false">
                        <template slot="option" slot-scope="option" class="select-option"
                                  v-bind:class="{'unselectable' : !option.available}">
                            <span>{{ option.display + (option.description ? '-' + option.description : '') }}</span>
                        </template>
                    </v-select>
                </div>
                <div clasS="col-xs-2 value-panel">
                    <input type="checkbox" v-model="rule.boolValue"
                           v-if="rule.rule_id && ruleMap[rule.rule_id].type == 'BOOLEAN'" @change="ruleChanged(rule)">
                    <div class="string-list" v-if="rule.rule_id && ruleMap[rule.rule_id].type == 'LIST_STRING'"
                         :key="rule.strValues.length">
                        <input type="text" v-model="ruleStrValue.value" v-for="ruleStrValue in rule.strValues"
                               :key="ruleStrValue.value"
                               @change="ruleChanged(rule)"
                               v-bind:placeholder="ruleMap[rule.rule_id].name == 'ip_country' ? 'Enter a country name' : (ruleMap[rule.rule_id].name  == 'email_regex' ? 'Enter a regex' : 'Enter a value')">
                        <a @click="addStringValue(rule)">
                            <i class="fa fa-plus"></i> Add Item
                        </a>
                    </div>
                </div>
                <div clasS="col-xs-3 col-md-2">
                    <v-select :options="actionOptions" v-model="rule.action" @input="ruleChanged(rule)"
                              :clearable="false"></v-select>
                </div>
                <div clasS="col-xs-1 col-md-2 action-panel">
                    <a @click="saveRule(rule)" v-bind:disabled="!rule.unsaved">
                        <i class="fa fa-floppy-o" title="Save"></i>
                    </a>
                    <a @click="deleteRule(rule)">
                        <i class="fa fa-trash" title="Delete"></i>
                    </a>
                </div>
            </li>
        </ul>
        <div class="row button-container">
            <span clasS="col-md-3">
                <a class="btn button-success" @click="addRule()">
                    <i class="fa fa-plus" title="Add Rule"></i>
                    Add Rule
                </a>
                <a class="btn button-success" @click="saveRules()">
                    <i class="fa fa-floppy-o" title="Save Rules"></i>
                    Save Rules
                </a>
            </span>
        </div>
    </div>
</template>

<script>
import Account from './../../store/account'

export default {
    data: function () {
        return {
            ruleTypes: [],
            rules: [],
            ruleTypeOptions: [],
            actionOptions: ['APPROVE', 'REVIEW', 'REJECT'],
            ruleMap: {},
            account: Account,
            loading: false
        }
    },
    created: function () {
        this.loading = true
        this.$http.get('internal/validation-rule/').then(
            (xhr) => {
                if (xhr.data) {
                    this.ruleTypes = xhr.data
                    xhr.data.forEach((item) => {
                        this.ruleMap[item.id] = item

                        if (item.available) {
                            this.ruleTypeOptions.push(item)
                        }
                    })
                }

                this.fetchRules()
            },
            (xhr) => {
                this.fetchRules()
            }
        )
    },
    ready: function () {

    },
    methods: {
        fetchRules: function () {
            this.$http.get('validation-rule').then(
                (xhr) => {
                    if (xhr.data) {
                        xhr.data.forEach((rule) => {
                            rule.strValues = []
                            if (rule.strValue) {
                                rule.strValues = rule.strValue.split(';-;')
                            }

                            this.rules.push(rule)
                        })
                    }
                    if (xhr.data.length === 0) {
                        this.addRule()
                    }

                    this.loading = false
                },
                (xhr) => {
                    this.loading = false
                }
            )
        },
        addRule: function () {
            let maxRule = 5 // For free plan
            if (this.account.model.plan) {
                maxRule = this.account.model.plan.metadata.validation_rules
            }
            if (this.rules.length - this.getComplexRuleCount() >= maxRule) {
                this.$alert.warning({message: 'With your current plan, you can add at most ' + maxRule + ' validation rules.'})
                return
            }

            this.rules.push(this.getARule())
        },
        addComplexRule: function (rule) {
            let maxRule = 0 // For free plan
            if (this.account.model.plan) {
                maxRule = this.account.model.plan.metadata.complex_rules
            }

            if (this.getComplexRuleCount() >= maxRule) {
                let message = 'With your current plan, you can add at most ' + maxRule + ' complex rules.'
                if (maxRule === 0) {
                    message = 'With your current plan, you cant use any complex rules. Please upgrade to use them!'
                }
                this.$alert.warning({message: message})
                return
            }

            rule.is_grouped = true
            rule.group_type = 'AND'

            let complexRule = this.getARule()
            complexRule.is_grouped = true
            complexRule.group_type = 'AND'

            this.rules.splice(this.rules.indexOf(rule), 0, complexRule)
        },
        getARule: function () {
            return {
                rule_id: this.ruleTypes[0].id,
                action: 'REVIEW',
                active: true,
                comparator: 'EQ',
                order: this.rules[this.rules.length - 1].order + 1,
                unsaved: true
            }
        },
        getComplexRuleCount: function () {
            let activeComplexRules = 0
            this.rules.forEach((rule) => {
                if (rule.is_grouped) {
                    activeComplexRules++
                }
            })

            return activeComplexRules / 2
        },
        addStringValue: function (rule) {
            if (rule.strValues.length >= 10) {
                this.$alert.warning({message: 'You can add at most 10 items for this field.'})
                return
            }

            rule.strValues.push({'value': ''})

            this.ruleChanged(rule)
        },
        saveRules: function () {
            var self = this
            this.rules.forEach((rule) => {
                if (rule.unsaved) {
                    self.saveRule(rule, true)
                }
            })
        },
        ruleChanged: function (rule, update) {
            rule.unsaved = true
            if (update) {
                rule.strValues = []
                rule.strValue = null
                rule.boolValue = false
                if (this.ruleMap[rule.rule_id].type === 'LIST_STRING') {
                    rule.strValues.push({value: null})
                }
            }
        },
        deleteRule: function (rule) {
            if (rule.id) {
                this.$http.delete('validation-rule/' + rule.id).then(
                    (xhr) => {
                        this.$alert.info({message: this.$i18n.t('common.delete_success')})

                        rule.unsaved = false
                        this.loading = false
                    },
                    (xhr) => {
                        this.$alert.warning({message: this.$i18n.t('common.delete_failed')})

                        this.loading = false
                    }
                )
            }

            this.rules.splice(this.rules.indexOf(rule), 1)
        },
        saveRule: function (rawRule) {
            this.loading = true

            // Prepare data to save, copy strValues into strValue
            let rule = JSON.parse(JSON.stringify(rawRule))
            if (rule.strValues) {
                rule.strValue = rule.strValues.join(';-;')
                delete rule.strValues
            }

            if (rule.id) {
                this.$http.put('validation-rule/' + rule.id, rule, {emulateJSON: false}).then(
                    (xhr) => {
                        this.$alert.info({message: this.$i18n.t('common.update_success')})

                        rawRule.unsaved = false
                        this.loading = false
                    },
                    (xhr) => {
                        this.$alert.info({message: this.$i18n.t('common.update_failed') + xhr.data.message})

                        this.loading = false
                    }
                )
            } else {
                this.$http.post('validation-rule', rule, {emulateJSON: false}).then(
                    (xhr) => {
                        this.$alert.info({message: this.$i18n.t('common.save_success')})

                        rawRule.unsaved = false
                        this.loading = false
                    },
                    (xhr) => {
                        this.$alert.info({message: this.$i18n.t('common.save_failed') + xhr.data.message})

                        this.loading = false
                    }
                )
            }
        }
    },
    events: {}
}
</script>
<style>
.rules-page li.row {
    margin: 10px 0px;
}

.rules-page li i {
    font-size: 20px;
    cursor: pointer;
    color: grey;
}

.rules-page .value-panel a {
    cursor: pointer;
}

.value-panel .string-list a {
    display: block;
    margin-top: 5px;
}

.value-panel .string-list input {
    margin-bottom: 2px;
}

.rules-page .action-panel a {
    margin-right: 10px;
}

.rules-page .action-panel a[disabled] {
    pointer-events: none;
}

.rules-page .action-panel a[disabled] i {
    color: lightgrey;
    cursor: auto;
}

.rules-page .button-container {
    margin-top: 30px;
}

.rules-page .button-container .btn {
    margin-right: 10px;
}

.rules-page .select-option.unselectable {
    color: grey;
    pointer-events: none;
}
</style>
