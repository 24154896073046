<template>
    <div></div>
</template>

<script>
export default {
    data() {
        return {}
    },
    created: function () {
    },
    methods: {}
}
</script>
