const Account = {
    model: null,
    setModel: function (account) {
        Account.model = account
    },
    applyScreenChange: function () {

    }
}

export default Account
