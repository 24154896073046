export default {
    'back_to_login': 'Back to Login',
    'login': 'Let\'s Login!',
    'signup': 'Sign up now!',
    'your_email': 'Your email',
    'your_name': 'Your name',
    'your_password': 'Your password',
    'your_repassword': 'Re-enter your password',
    'lets_recovery': 'Let\'s recover your password',
    'logout': 'Logout',
    'account_deleted': 'Account deleted',
    'account_deleted_successfully': 'Your account has successfully been deleted.',
    'account_deleted_sorry_to_go': 'We are sorry to see you go. If there is anything we can do, please let us know!',
    'lost_your_password': 'Lost your password?',
    'lost_password': 'Lost password!',
    'create_account': 'Create an account',
    'goodbye': 'Goodbye!',
    'welcome_back': 'Welcome to FraudRadar.io!',
    'logout_successful': 'You have been logout from your account. <br> We hope to see you back soon. (If your login attempt is not successful, please try to clear your browser cache)!',
    'retrieve_password': 'Retrieve my password',
    'not_verified': 'Please verify your account to get your free credits!',
    'reset_link_sent': 'An email has been sent to you. Click on the link in the email to connect instantly :)',
    'resetted_password': 'Your password has been resetted, you can try to login with your new password.',
    'change_password': 'Change Password',
    'reset_link_expired': 'This link to reset your password has expired. For your security, this link expires after a certain time. Please obtain a new link to reset your password!',
    'lost_description': 'I tend to forget my password all the time, too. <br>  I think it might be the age.. <br> Just enter your email below and I\'ll send you a link to create a new one!',
    'nice_to_meet': 'Nice to meet you!',
    'already_have_account': 'Already have an account? Login',
    'resend_verification_link': 'Resend Verification Link',
    'successfully_resend': 'Successfully resend the verification link, please check your email!',
    'verification_problem': 'Incorrect link for registration. Please click the link on the e-mail verification message or obtain a new one.',
    'verification_link_expired': 'This link has expired. Please use resend verification link to receive a new one!',
    'invalid_verify_link': 'Invalid link. Please use resend verification link to receive a new one!',
    'already_verified': 'Your account is already verified. Please try to login with your credentials!',
    'verify_success': 'Verification is successful, you can login now!',
    'successfully_registered': 'You have registered, please check your email for account verification!',
    'no_bots': 'Detected fraud activity, please do not use auto refreshers! Your account production has been decreased!',
    'stop_refresh_or_reset': 'Please, do not use refreshers or your account could be penalized by production decrease!'
}
