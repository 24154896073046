<template>
    <div class="settings-modal">
        <h3>Update Credit Card</h3>

        <form v-on:submit.prevent="update" ref="form">
            <div style="position: relative">
                <label>Credit card number</label>
                <input type="text" name="creditcard_number" required v-model="creditcard.number" v-bind:placeholder="numberPlaceholder" v-on:keyup="updateCreditCardLogo"/>
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                     viewBox="0 0 219 40" style="enable-background:new 0 0 219 40;" xml:space="preserve" class="creditcard">
                    <g class="credit-cards card-amex">
                	<path class="st0" d="M219,35c0,2.8-2.2,5-5,5h-50c-2.8,0-5-2.2-5-5V5c0-2.8,2.2-5,5-5h50c2.8,0,5,2.2,5,5V35z"/>
                        <path class="st1" d="M217.5,1.5c0.9,0.9,1.5,2.2,1.5,3.5v15v15c0,1.4-0.6,2.6-1.5,3.5S215.3,40,214,40h-25h-25
                		c-1.4,0-2.6-0.6-3.5-1.5L217.5,1.5z"/>
                        <g>
                		<path class="st2" d="M176.6,24.9l-0.5-1.7h-3l-0.5,1.7h-2.8l3-9.3h3.3l3.1,9.3H176.6z M175.6,21.1l-0.4-1.5
                			c-0.1-0.3-0.2-0.8-0.3-1.3s-0.2-0.9-0.3-1.2c0,0.2-0.1,0.6-0.2,1.1s-0.4,1.5-0.7,2.9H175.6L175.6,21.1z"/>
                            <path class="st2" d="M184.3,24.9l-1.9-6.7h-0.1c0.1,1.1,0.1,2,0.1,2.7v4h-2.2v-9.3h3.3l1.9,6.6h0.1l1.9-6.6h3.3v9.3h-2.3v-4.1
                			c0-0.2,0-0.4,0-0.7s0-0.9,0.1-1.9h-0.1l-1.9,6.7L184.3,24.9L184.3,24.9z"/>
                            <path class="st2" d="M198.1,24.9h-5.5v-9.3h5.5v2h-3v1.5h2.8v2h-2.8v1.8h3V24.9z"/>
                            <path class="st2" d="M208.1,24.9h-2.9l-1.8-2.9l-1.8,2.9h-2.8l3.1-4.7l-2.9-4.5h2.8l1.7,2.9l1.6-2.9h2.9l-2.9,4.7L208.1,24.9z"/>
                	</g>
                        <g>
                		<path class="st2" d="M176.6,24.9l-0.5-1.7h-3l-0.5,1.7h-2.8l3-9.3h3.3l3.1,9.3H176.6z M175.6,21.1l-0.4-1.5
                			c-0.1-0.3-0.2-0.8-0.3-1.3s-0.2-0.9-0.3-1.2c0,0.2-0.1,0.6-0.2,1.1s-0.4,1.5-0.7,2.9H175.6L175.6,21.1z"/>
                            <path class="st2" d="M184.3,24.9l-1.9-6.7h-0.1c0.1,1.1,0.1,2,0.1,2.7v4h-2.2v-9.3h3.3l1.9,6.6h0.1l1.9-6.6h3.3v9.3h-2.3v-4.1
                			c0-0.2,0-0.4,0-0.7s0-0.9,0.1-1.9h-0.1l-1.9,6.7L184.3,24.9L184.3,24.9z"/>
                            <path class="st2" d="M198.1,24.9h-5.5v-9.3h5.5v2h-3v1.5h2.8v2h-2.8v1.8h3V24.9z"/>
                            <path class="st2" d="M208.1,24.9h-2.9l-1.8-2.9l-1.8,2.9h-2.8l3.1-4.7l-2.9-4.5h2.8l1.7,2.9l1.6-2.9h2.9l-2.9,4.7L208.1,24.9z"/>
                	</g>
                        <path class="st3" d="M189,0"/>
                </g>
                    <g class="credit-cards card-mastercard">
                	<path class="st4" d="M139,35c0,2.8-2.2,5-5,5H84c-2.8,0-5-2.2-5-5V5c0-2.8,2.2-5,5-5h50c2.8,0,5,2.2,5,5V35z"/>
                        <path class="st5" d="M137.5,1.5c0.9,0.9,1.5,2.2,1.5,3.5v15v15c0,1.4-0.6,2.6-1.5,3.5S135.3,40,134,40h-25H84
                		c-1.4,0-2.6-0.6-3.5-1.5L137.5,1.5z"/>
                        <path class="st3" d="M109,0"/>
                        <g>
                		<path class="st6" d="M109,13.4c-1.8-2.1-4.5-3.4-7.5-3.4c-5.5,0-10,4.5-10,10s4.5,10,10,10c3,0,5.7-1.3,7.5-3.4
                			c-1.6-1.8-2.5-4.1-2.5-6.6S107.4,15.2,109,13.4z"/>
                            <path class="st6" d="M109,13.4c-1.6,1.8-2.5,4.1-2.5,6.6s0.9,4.8,2.5,6.6c1.6-1.8,2.5-4.1,2.5-6.6S110.6,15.2,109,13.4z"/>
                	</g>
                        <path class="st7" d="M116.5,10c-3,0-5.7,1.3-7.5,3.4c-0.4,0.5-0.8,1-1.1,1.6h2.3c0.4,0.6,0.6,1.3,0.9,2h-4c-0.2,0.6-0.4,1.3-0.4,2
                		h4.9c0,0.3,0.1,0.7,0.1,1s0,0.7-0.1,1h-4.9c0.1,0.7,0.2,1.4,0.4,2h4.1c-0.2,0.7-0.5,1.4-0.9,2H108c0.3,0.6,0.7,1.1,1.1,1.6
                		c1.8,2.1,4.5,3.4,7.5,3.4c5.5,0,10-4.5,10-10C126.5,14.5,122,10,116.5,10z"/>
                </g>
                    <g class="credit-cards card-visa">
                	<path class="st4" d="M0,10V5c0-2.8,2.2-5,5-5h48c2.8,0,5,2.2,5,5v5"/>
                        <path class="st8" d="M58,30v5c0,2.8-2.2,4-5,4H5c-2.8,0-5-1.2-5-4v-5"/>
                        <g>
                		<path class="st4" d="M16.4,23.4c0.4-1.1,0.7-1.9,0.9-2.2l3.4-6.9h2.5l-5.8,11.4h-2.6l-1-11.4h2.3l0.4,6.9c0,0.2,0,0.6,0,1
                			C16.4,22.7,16.4,23.1,16.4,23.4L16.4,23.4z"/>
                            <path class="st4" d="M22.1,25.7l2.4-11.4h2.4l-2.4,11.4H22.1z"/>
                            <path class="st4" d="M34.2,22.3c0,1.1-0.4,1.9-1.2,2.6c-0.8,0.6-1.8,0.9-3.1,0.9c-1.2,0-2.1-0.2-2.8-0.7V23c1,0.6,1.9,0.8,2.8,0.8
                			c0.6,0,1-0.1,1.4-0.3c0.3-0.2,0.5-0.5,0.5-0.9c0-0.2,0-0.4-0.1-0.6s-0.2-0.3-0.3-0.5c-0.1-0.1-0.5-0.4-1-0.8
                			c-0.7-0.5-1.2-1-1.5-1.5s-0.4-1-0.4-1.6c0-0.7,0.2-1.3,0.5-1.8s0.8-0.9,1.4-1.2s1.3-0.4,2.1-0.4c1.1,0,2.2,0.3,3.1,0.8l-0.9,1.8
                			c-0.8-0.4-1.6-0.6-2.2-0.6c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.2-0.4,0.5-0.4,0.9c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.6,0.6,1.2,0.9
                			c0.6,0.4,1.1,0.9,1.4,1.4C34,21.1,34.2,21.7,34.2,22.3z"/>
                            <path class="st4" d="M41.8,23h-3.7l-1.3,2.7h-2.5l6-11.5h2.9l1.1,11.5H42L41.8,23z M41.7,21l-0.2-2.7c-0.1-0.7-0.1-1.3-0.1-2V16
                			c-0.2,0.6-0.5,1.3-0.9,2L39,21H41.7z"/>
                	</g>
                        <path class="st2" d="M29,0"/>
                </g>
                </svg>
            </div>
            <div class="expiration">
                <label>Expiration Date</label>
                <v-select name="month" class="input-month" :options="['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']" v-model="creditcard.month" :clearable="false" placeholder="Month" required="true"></v-select>
                <v-select name="year" class="input-year" :options="futureYears" v-model="creditcard.year" :clearable="false" placeholder="Year" required="true"></v-select>
            </div>
            <div class="cvc">
                <label>Security Code</label>
                <input type="text" placeholder="CVC" required v-model="creditcard.cvc"/>
            </div>
            <div class="button-container">
                <span style="margin-top: 5px">
                    <input type="submit" v-bind:value="$t('common.update')" class="btn" v-if="!saved"/>
                    <input type="button" v-bind:value="$t('common.saved')" class="btn" disabled v-if="saved">
                </span>
            </div>
        </form>
    </div>
</template>

<script>
/* global Stripe */
import Tooltips from './../misc/tooltips'

export default {
    components: {},
    data: function () {
        return {
            numberPlaceholder: null,
            futureYears: [],
            creditcard: {
                number: null,
                year: null,
                month: null,
                cvc: null
            },
            company_name: null,
            zipcode: null,
            saved: false
        }
    },
    created: function () {
        this.futureYears = []
        let currentYear = parseInt(new Date().getUTCFullYear().toString().substr(2, 2), 10)
        for (let i = 0; i < 10; i++) {
            this.futureYears.push(currentYear + i)
        }

        this.$http.get('internal/payment/cc').then(
            (xhr) => {
                if (xhr.data) {
                    this.numberPlaceholder = '**** **** **** ' + (xhr.data.cc_last4 || '1234')
                    this.month = xhr.data.cc_expires.split('/')[0]
                    this.year = xhr.data.cc_expires.split('/')[1]
                    this.company_name = xhr.data.company_name
                    this.zipcode = xhr.data.zipcode
                }
                this.loading = false
            },
            (xhr) => {
                this.loading = false
            }
        )
    },
    methods: {
        update: function () {
            this.saved = true
            let errors = {}
            if (this.creditcard.number === '') {
                errors['number'] = ['This value is required.']
            } else if (this.creditcard.number.length < 14 || this.creditcard.number.length > 16) {
                errors['number'] = ['The credit card number must contains 14 to 16 numbers.']
            }

            if (this.creditcard.month === '') {
                errors['month'] = ['This value is required.']
            }

            if (this.creditcard.year === '') {
                errors['year'] = ['This value is required.']
            }

            if (this.creditcard.cvc === '') {
                errors['cvc'] = ['This value is required.']
            }

            let form = Tooltips(this.$refs.paymentForm)
            form.clear()

            if (Object.getOwnPropertyNames(errors).length > 0) {
                form.error(errors)
                return false
            }

            Stripe.card.createToken({
                number: this.creditcard.number,
                exp_month: this.creditcard.month,
                exp_year: this.creditcard.year,
                cvc: this.creditcard.cvc,
                name: this.company_name,
                address_zip: this.zipcode
            }, (status, response) => {
                if (response['error']) {
                    let errorResult = {}
                    if (response['error'].param === 'exp_month') {
                        errorResult['month'] = [response['error'].message]
                    } else if (response['error'].param === 'exp_year') {
                        errorResult['year'] = [response['error'].message]
                    } else if (response['error'].param === 'address_zip') {
                        errorResult['number'] = [response['error'].message]
                    } else if (response['error'].param === 'address_city') {
                        errorResult['number'] = [response['error'].message]
                    } else if (response['error'].param === 'address_country') {
                        errorResult['number'] = [response['error'].message]
                    } else {
                        errorResult['number'] = [response['error'].message]
                    }

                    form.error(errorResult)
                    return false
                }

                let params = {
                    stripe: response['id']
                }

                this.$http.post('internal/payment/cc', params).then((xhr) => {
                    this.$alert.info({message: this.$i18n.t('common.save_success')})
                }).catch((xhr) => {
                    form.error(xhr.data)
                })
            })
        },
        updateCreditCardLogo: function (event) {
            let parentNode = event.target.parentNode

            if (event.target.value === '' || ['3', '4', '5'].indexOf(event.target.value.substr(0, 1)) === -1) {
                Array.from(parentNode.querySelectorAll('.credit-cards')).map((item) => {
                    item.style.opacity = 1
                })

                return
            }

            Array.from(parentNode.querySelectorAll('.credit-cards')).map((item) => {
                item.style.opacity = 0.25
            })

            switch (event.target.value.substr(0, 1)) {
                case '4': // visa
                    parentNode.querySelector('.card-visa').style.opacity = 1
                    break
                case '5': // mastercard
                    parentNode.querySelector('.card-mastercard').style.opacity = 1
                    break
                case '3': // amex
                    parentNode.querySelector('.card-amex').style.opacity = 1
                    break
                default:
                    Array.from(parentNode.querySelectorAll('.credit-cards')).map((item) => {
                        item.style.opacity = 1
                    })
                    break
            }
        },
        resetBtnState: function () {
            this.saved = false
        }
    }
}
</script>
<style>
    .settings-modal .creditcard {
        right: 20px;
        top: 10px;
    }

    .settings-modal .expiration .v-select {
        display: inline-block;
        width: 198px;
        background-color: #fff;
    }

    .settings-modal .expiration .v-select > div {
        padding: 6px 0px;
    }
</style>
