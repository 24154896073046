<template>
    <div class="page-body report-page">
        <header>
            <span class="u-pull-left">
                <h3 class="breadcrumb">Dashboard</h3>
            </span>
        </header>
        <div class="row">
            <div class="col-xs-12 col-md-5 chart" id="signup-by-action-chart"
                 v-show="chartData && chartData['signup_by_action'].length != 0"></div>
            <div class="col-xs-12 col-md-5 chart chart-empty"
                 v-show="!chartData ||chartData['signup_by_action'].length == 0">
                <span v-show="chartData">No Data</span>
                <loading :show="!chartData"></loading>
            </div>
            <div class="col-xs-12 col-md-5 col-md-offset-1 chart" id="credits-spent"
                 v-show="chartData && chartData['credits_spent'].length != 0"></div>
            <div class="col-xs-12 col-md-5 col-md-offset-1 chart chart-empty"
                 v-show="!chartData ||chartData['credits_spent'].length == 0">
                <span v-show="chartData">No Data</span>
                <loading :show="!chartData"></loading>
            </div>

            <div class="col-xs-12 col-md-5 chart" id="signup-by-rule-review-chart"
                 v-show="chartData && chartData['signup_by_rule_review'].length != 0"></div>
            <div class="col-xs-12 col-md-5 chart chart-empty"
                 v-show="!chartData ||chartData['signup_by_rule_review'].length == 0">
                <span v-show="chartData">No Data</span>
                <loading :show="!chartData"></loading>
            </div>
            <div class="col-xs-12 col-md-5 col-md-offset-1 chart" id="signup-by-rule-reject-chart"
                 v-show="chartData && chartData['signup_by_rule_reject'].length != 0"></div>
            <div class="col-xs-12 col-md-5 col-md-offset-1 chart chart-empty"
                 v-show="!chartData ||chartData['signup_by_rule_reject'].length == 0">
                <span v-show="chartData">No Data</span>
                <loading :show="!chartData"></loading>
            </div>
        </div>
    </div>
</template>

<script>
/* global google */
export default {
    data: function () {
        return {
            chartData: null,
            loading: false
        }
    },
    created: function () {
        this.$http.get('internal/report/chart/load-dashboard').then(
            (xhr) => {
                if (xhr.data) {
                    this.chartData = xhr.data

                    // Load the Visualization API and the corechart package.
                    google.charts.load('current', {'packages': ['corechart']})

                    // Set a callback to run when the Google Visualization API is loaded.
                    google.charts.setOnLoadCallback(this.drawCharts)
                }
                this.loading = false
            },
            (xhr) => {
                this.loading = false
            }
        )
    },
    ready: function () {

    },
    methods: {
        drawCharts: function () {
            this.drawLineChart(['APPROVE', 'REVIEW', 'REJECT'], 'Query by Action', 'signup-by-action-chart', this.chartData['signup_by_action'], 'right')

            this.drawLineChart(['Credits'], 'Credits Spent', 'credits-spent', this.chartData['credits_spent'], 'bottom')

            let ruleDisplays = []
            this.chartData.rules.forEach((rule) => {
                ruleDisplays.push(rule.display)
            })
            this.drawLineChart(ruleDisplays, 'Signup Review Actions by Rule', 'signup-by-rule-review-chart', this.chartData['signup_by_rule_review'], 'right')
            this.drawLineChart(ruleDisplays, 'Signup Reject Actions by Rule', 'signup-by-rule-reject-chart', this.chartData['signup_by_rule_reject'], 'right')
        },
        drawLineChart: function (types, title, elementId, rawData, legendPosition) {
            let chartData = this.prepareData(types, rawData)
            let data = google.visualization.arrayToDataTable(chartData)

            let options = {
                title: title,
                legend: {position: legendPosition}
            }

            let chart = new google.visualization.LineChart(document.getElementById(elementId))

            chart.draw(data, options)
        },
        prepareData: function (types, rawData) {
            let chartData = [['Date']]
            for (let index in types) {
                chartData[0].push(types[index])
            }

            let self = this
            rawData.forEach(function (rawDatum) {
                self.getOrCreateDate(chartData, rawDatum[0], types, rawDatum[1], rawDatum[2])
            })

            return chartData
        },
        getOrCreateDate: function (chartData, date, types, type, value) {
            let item = null
            chartData.forEach(function (row) {
                if (row[0] === date) {
                    item = row
                }
            })

            if (!item) {
                item = [date]
                types.forEach(() => {
                    item.push(0)
                })
                chartData.push(item)
            }

            if (types.length > 1) {
                item[types.indexOf(type) + 1] = value
            } else {
                item[1] = type
            }
        }
    },
    events: {}
}
</script>

<style>
.chart {
    height: 300px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.chart.chart-empty {
    background: white;
    text-align: center;
}

.chart.chart-empty > span,
.chart.chart-empty > .loading {
    color: grey;
    top: 50%;
    position: absolute;
    font-size: 16px;
    display: initial;
}
</style>
