<template>
    <div class="testimonials">
        <p>More then 1 million accounts & actions are validated through FraudRadar.</p>
        <div class="card">
            <div class="card-body card-body-shadowlow">
                <div class="row">
                    <div class="col-md-2">
                        <img height="50" width="50" class="rounded-circle" src="/img/testimonials/dubois.jpg"/>
                    </div>
                    <div class="col-md-10">
                        <p style="color: #686868;">
                            This software really helped me to get rid of unnecessary accounts and ease me to focus on
                            really marketting values.
                            <br><a href="#"><span style="color: #80B340;">Cyril Dubois from France</span></a>
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-body card-body-shadowlow">
                <div class="row">
                    <div class="col-md-2">
                        <img height="50" width="50" class="rounded-circle" src="/img/testimonials/wagner.jpg"/>
                    </div>
                    <div class="col-md-10">
                        <p style="color: #686868;">
                            I was able to clean my database out of spam users, easier to go over the real data.
                            If you want to easily find and flag fraud attempts, this the right tool.
                            <br><a href="#"><span style="color: #80B340;">Jonas Wagner from Germany</span></a>
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-body card-body-shadowlow">
                <div class="row">
                    <div class="col-md-2">
                        <img height="50" width="50" class="rounded-circle" src="/img/testimonials/donovan.jpg"/>
                    </div>
                    <div class="col-md-10">
                        <p style="color: #686868;">
                            It was like a magic to detect many spam accounts that saved us a lot.
                            <br>
                            <a href="#"><span style="color: #80B340;">July Donovan from U.S.A.</span></a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'testimonials'
}
</script>

<style>
.testimonials > p {
    margin-bottom: 10px;
}

.testimonials p a {
    margin-top: 5px;
    display: block;
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    margin-bottom: 20px;
}

.card-body {
    flex: 1 1 auto;
    padding: 1.25rem;
}

.card-body p {
    text-align: left;
    font-size: 16px;
    margin-bottom: 10px;
}

.card-body-shadowlow {
    box-shadow: 0 1px 3px 0 rgb(0 0 0/11%);
}
</style>
