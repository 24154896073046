<template>
    <div ref="appElement" style="position:relative;height:100%;">
        <div class="integration-list">
            <div class="integration-header"></div>
            <div class="integration-body integration-prospecting">
                <div class="integration-title">Plugins</div>
                <ul>
                    <li class="ml-item">
                        <div class="int-logo">
                            <img src="/img/integrations/wordpress.svg"/>
                        </div>
                        <div class="integration-description">
                            <div>Wordpress Plugin</div>
                            <span class="int-status">Not installed</span>
                        </div>

                        <div class="button-panel">
                            <a href="https://wordpress.org/plugins/fraudradar/" title="Install the plugin"
                               class="btn-stack" target="_blank">Install the plugin</a>
                        </div>
                    </li>
                    <li class="ml-item"
                        :class="(integration.active ? '' : 'ml-soon') + ' ml-' + integration.title.toLowerCase()"
                        v-for="integration in integrations"
                        :key="integration.name">
                        <div class="int-logo">
                            <img v-bind:src="'/img/integrations/' + integration.name + '.svg'"
                                 style="max-width: 80px"/>
                        </div>
                        <div class="integration-description">
                            <div>{{ integration.title }}</div>
                            <template v-if="integration.is_connected">
                                <div class="connected-panel">
                                    <span class="int-status int-status-ok">Connected</span>

                                    <input type="checkbox" class="switch green" :id="'switch-' + integration.title"
                                           checked v-on:click="revoke(integration)"/>
                                    <label :for="'switch-' + integration.title" class="switch-btn"></label>
                                </div>
                            </template>
                            <template v-else>
                                <span class="int-status">Not connected</span>
                            </template>
                        </div>

                        <div class="button-panel">
                            <template v-if="integration.is_connected">
                                <template v-if="integration.oauth">
                                    <a href="javascript:;" :title="'Revoke ' + integration.title"
                                       v-on:click="revoke(integration)" class="btn-stack">Revoke
                                        {{ integration.title }}</a>
                                </template>
                                <template v-else>
                                    <router-link :to="{ path: '/integrations/' + integration.name + '/edit' }"
                                                 :title="'Add ' + integration.title" class="btn-stack">Configure
                                        {{ integration.title }}
                                    </router-link>
                                </template>
                            </template>
                            <template v-else>
                                <template v-if="integration.oauth">
                                    <a href="javascript:;" :title="'Connect to ' + integration.title" class="btn-stack"
                                       v-on:click="connectToOAuth(integration)" v-if="!integration.showForm">Connect to {{ integration.title }}</a>
                                </template>
                                <template v-else>
                                    <router-link :to="{ path: '/integrations/' + integration.name + '/add' }"
                                                 :title="'Add ' + integration.title" class="btn-stack">Add
                                        {{ integration.title }}
                                    </router-link>
                                </template>
                            </template>
                        </div>
                        <div v-if="integration.oauth && integration.preFields.length && integration.showForm" class="integration-pre-form">
                            <div v-for="field in integration.preFields" :key="field.name">
                                <label :for="'integration-field-' + field.name">{{ field.label }}
                                    <span v-if="!field.required">Optional</span>
                                </label>
                                <template v-if="field.type === 'text'">
                                    <input :id="'integration-field-' + field.name" type="text" :name="field.name"
                                           :placeholder="field.placeholder" v-bind:required="field.required"
                                           v-model="field.value"/>
                                    <span class="error-explain" v-if="error">{{ error }}</span>
                                </template>

                                <template v-if="field.description">
                                    <p class="help-description">{{ field.description }}</p>
                                </template>
                            </div>

                            <div class="button-container">
                                <input type="button" @click="submitOauthField(integration)"
                                       v-bind:value="'Go ahead and connect to ' + integration.title"
                                       class="btn button-success"/>
                                <a class="cancel" @click="integration.showForm = false">{{ $t('common.cancel') }}</a>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <div class="integration-list">
            <div class="integration-header"></div>
            <div class="integration-body integration-prospecting">
                <div class="integration-title">API Key</div>

                <div class="integration-detail ml-api" style="margin-top: 10px">
                    <div class="ml-api-info">
                        <p>The API is simple to integrate if you want to directly access FraudRadar endpoints.
                            <a href="https://fraudradar.io/v1/" target="_blank">Check the documentation</a></p>
                    </div>
                    <div class="api-key">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 20">
                            <path
                                d="M5,12.1V9H7V7H5V6H6V4H5V3H8V1H5V0H3V12.1A4,4,0,1,0,8,16,4.08,4.08,0,0,0,5,12.1ZM4,18a2,2,0,1,1,2-2A2,2,0,0,1,4,18Z"/>
                        </svg>
                        <input type="text" v-model="apiKey" id="api-key-input" readonly/>
                        <button class="button api-key-button" v-clipboard:copy="apiKey" v-clipboard:success="onCopy">
                            {{ copyText }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            loading: true,
            apiKey: null,
            integrations: [],
            copyText: 'Copy',
            error: null
        }
    },
    created: function () {
        this.loading = true
        this.$http.get('internal/account/api').then(
            (xhr) => {
                if (xhr.data) {
                    this.apiKey = xhr.data.token
                }
                this.loading = false
            },
            (xhr) => {
                this.loading = false
            }
        )

        this.$http.get('integration/').then((xhr) => {
            xhr.data.result.forEach((integration) => {
                integration.preFields = this.getPreFields(integration)
                integration.showForm = false
            })
            this.integrations = xhr.data.result
        })
    },
    methods: {
        onCopy: function () {
            this.copyText = 'Copied'
            setTimeout(() => {
                this.copyText = 'Copy'
            }, 3000)
        },
        connectToOAuth: function (integration) {
            if (integration.preFields.length > 0) {
                integration.showForm = true
            } else {
                this.redirectToOauth(integration)
            }
        },
        submitOauthField(integration) {
            const noValue = integration.preFields.some(field => !field.value);
            if (noValue) {
                this.error = '*Please fill all the required fields'
                return;
            }
            this.redirectToOauth(integration, integration.preFields.map((filter) => filter.name + '=' + filter.value))
        },
        redirectToOauth(integration, additionalParams) {
            window.location.href = this.$http.options.root + '/integration/' + integration.name + '/oauth-init?token=' + this.$http.options.headers['auth-token']
                + (additionalParams ? '&' + additionalParams.join('&') : '')
        },
        getPreFields(integration) {
            return integration.fields.filter((filter) => filter.phase === 'PRE')
        },
        revoke: function (integration) {
            this.$http.delete('integration/' + integration.name).then((xhr) => {
                this.$router.push('/integration/')
            }).catch((xhr) => {
                this.error = xhr.data.error
            })
        }
    }
}
</script>

<style scoped>

.integration-list {
    clear: both;
    max-width: 1000px;
    margin: 0 auto;
}

.integration-header {
    font-size: 24px;
    color: #e48130;
    font-weight: 300;
    line-height: 29px;
    margin: 18px 0;
}

.integration-body {
    padding: 28px;
    background: white;
    border-radius: 3px;
    border: 1px solid rgb(221, 221, 221);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
}

.integration-list .ml-item {
    min-height: 40px;
    border-bottom: 2px solid rgba(228, 224, 221, 0.5);
}

.integration-list .pull-left {
    float: left;
    margin-right: 5px;
}

.integration-list .pull-right {
    float: right;
}

.integration-title {
    font-size: 20px;
    font-weight: 300;
    line-height: 24px;
    color: #6e6c6a;
    padding-bottom: 28px;
    border-bottom: 2px solid rgba(228, 224, 221, 0.5);
}

.integration-body .ml-item {
    padding: 16px 31px 16px 20px;
    margin: 0px;
}

.integration-body .ml-item .integration-description {
    display: inline-block;
    margin-left: 31px;
    color: #6e6c6a;
    font-size: 15px;
    letter-spacing: 0.5px;
    line-height: 18px;
}

.integration-body .ml-item .integration-description > span {
    font-size: 11px;
    font-weight: normal;
    line-height: 13px;
}

.integration-body .ml-item .button-panel {
    display: inline-block;
    float: right;
}

.integration-body .ml-item .button-panel a {
    width: 180px;
}

.integration-body .ml-item .int-logo {
    width: 85px;
    display: inline-block;
    padding: 0px;
}

.integration-body .ml-item .int-logo img {
    height: 32px;
    max-width: 85px;
    display: block;
    margin: 0 auto;
}

.integration-body .ml-item.ml-pipedrive .int-logo img,
.integration-body .ml-item.ml-zapier .int-logo img,
.integration-body .ml-item.ml-hubspot .int-logo img,
.integration-body .ml-item.ml-mailchimp .int-logo img,
.integration-body .ml-item.ml-sendgrid .int-logo img {
    width: 64px;
    height: auto;
}

.integration-detail {
    display: inline-block;
}

.integration-detail .int-logo img {
    height: 40px;
}

.integration-detail .api-key {
    float: right;
    width: 395px;
    border: 1px solid #dcd7d3;
    border-radius: 3px;
    padding: 2px;
}

.integration-detail .api-key svg {
    width: 7px;
    float: left;
    display: inline-block;
    margin-left: 20px;
    fill: #a2a1a0;
    margin-top: 13px;
}

.integration-detail .api-key input {
    width: 310px;
    color: #6e6c6a;
    border: none;
    padding: 0 10px;
    margin: 0;
}

.integration-detail .api-key input:active, .ml-item .api-key input:focus {
    border: none;
    box-shadow: none;
}

.api-key button, .api-key button:hover {
    font-size: 12px;
    width: 42px;
    text-align: center;
    font-size: 9px;
    padding: 0px;
}

.api-key button.copied, .api-key button.copied:hover {
    color: #63bf56;
    background: transparent;
}


.ml-box {
    padding: 20px;
    text-align: center;
    position: relative;
    float: left;
}

.ml-box h3,
.integration-detail h3 {
    font-size: 15px;
    display: block;
    text-align: center;
    color: #6e6c6a;
    font-weight: 500;
    max-width: 250px;
    margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;
    letter-spacing: .5px;
    margin-top: 10px;
}

.ml-box p {
    font-size: 15px;
}

.ml-box a {
    width: auto !important;
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 20px;
}

.ml-box .int-logo img {
    height: 45px;
    width: auto;
}

.ml-soon {
    opacity: .5;
}

.ml-soon a.btn-stack:hover {
    border: 1px solid #e8e8e8;
    color: #989491;
    cursor: default;
}

.int-status {
    font-size: 11px;
    color: #dcd7d3;
    font-weight: bold;
    display: block;
    margin-top: 5px;
}

.int-status:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    border-radius: 100%;
    width: 7px;
    height: 7px;
    background: #dcd7d3;
    margin-right: 5px;
    margin-bottom: 2px
}

.int-status-ok {
    color: #5fbe52;
}

.int-status-ok:before {
    background: #5fbe52;
}

.btn-stack:hover {
    border: 1px solid #63bf56;
}

.switch-btn {
    position: absolute !important;
    top: 0;
    right: 0;
}

.integration-description .switch + label {
    margin: 0px;
}


.ml-api {
    min-height: 50px;
    width: 100%;
}

.ml-api p {
    font-size: 12px;
    color: #9b9794;
    margin-top: 7px;
    margin-bottom: 0;
}

.ml-api p a {
    position: relative;
    bottom: auto;
    top: auto;
    right: auto;
    left: auto;
    color: #9b9794;
    text-decoration: none;
    border-bottom: 1px solid #e0e0e0;
}

.ml-api .int-logo, .ml-api-info {
    float: left;
    text-align: left;
}

.ml-api:last-child .ml-api-info {
    margin-left: 20px;
    width: 75%;
}

.ml-api-info h3 {
    text-align: left;
    padding: 0;
    margin: 0;
}

.api-key.activate-button {
    width: 116px;
    border: none;
    right: 71px;
    height: initial;
}

.api-key.activate-button span {
    margin-bottom: 0px;
    padding-left: 10px;
    padding-right: 10px;
}

.api-key.verify-balance {
    height: auto;
    top: 20px;
    margin-top: 0px;
    padding: 7px 16px;
    width: auto;
}

.api-key.verify-balance h3 {
    margin-top: 0px;
}

.integration-onboarding {
    background-color: #5bc04c;
    color: #FFF;
    font-size: 13px;
    line-height: 16px;
    font-weight: bold;
    height: 60px;
    padding: 21px;
    text-align: center;
}

.integration-onboarding input {
    background-color: white;
    border-radius: 3px;
    color: #444;
    font-size: 11px;
    line-height: 13px;
    font-weight: bold;
    width: 90px;
    padding: 10px 20px;
    margin-top: -8px;
    margin-left: 20px;
    cursor: pointer;
}

.integration-body .connected-panel {
    width: 140px;
    position: relative;
}

.integration-prospecting .ml-api .ml-api-info {
    width: 40%;
}

.integration-detail.ml-api {
    padding-right: 33px;
}

.integration-pre-form {
    padding-left: 120px;
    padding-top: 25px;
}

.integration-pre-form input {
    width: 300px;
}


@media screen and (max-width: 560px) {
    .integration-onboarding {
        padding: 0px;
        height: 100px;
    }

    .integration-onboarding input {
        display: block;
        margin: 5px auto;
        left: calc(50% - 45px);
    }

    .integration-body .ml-item {
        padding: 9px 0px;
    }

    .integration-body .ml-item .integration-description {
        margin-left: 5px;
    }

    .integration-body .ml-item .button-panel a {
        width: 100px;
        padding: 2px 9px;
    }

    .integration-body .connected-panel {
        width: 120px;
    }

    .integration-prospecting .ml-api-info {
        width: 75%;
    }

    .integration-detail .api-key {
        width: 300px;
    }

    .integration-detail .api-key svg {
        margin-left: 10px;
    }

    .integration-detail .api-key input {
        width: 235px;
        font-size: 11px;
    }

    .integration-detail.ml-api {
        padding-right: initial;
    }
}
</style>
