<template>
    <div v-if="integration">
        <div class="page-header">
            <h2>{{ integration.title }}</h2>
            <p>{{ integration.description }}</p>
            <router-link class="btn-stack btn-back" to="/integrations">
                Back to the integrations
            </router-link>
        </div>

        <form v-on:submit.prevent="save" class="int-form">
            <div v-for="field in processedFilters" :key="field.name">
                <label :for="'integration-field-' + field.name">{{ field.label }}
                    <span v-if="!field.required">Optional</span>
                </label>
                <template v-if="field.type === 'text'">
                    <input :id="'integration-field-' + field.name" type="text" :name="field.name"
                           :placeholder="field.placeholder" v-bind:required="field.required"
                           v-on:change="reloadEndpoint(true)" v-model="formdata[field.name]"/>
                    <span class="error-explain" v-if="error">{{ error }}</span>
                </template>
                <template v-if="field.endpoint">
                    <select :name="field.name" :id="'integration-field-' + field.name"
                            v-bind="{required: field.required, disabled: !fieldValues[field.name]}"
                            v-model="formdata[field.name]" v-on:change="reloadEndpoint(field.reload_endpoints)">
                        <option value="">No values</option>
                        <option v-for="option in fieldValues[field.name]" v-bind:value="option.value"
                                :key="option.display">{{
                                option.display
                            }}
                        </option>
                    </select>
                </template>

                <template v-if="field.description">
                    <p class="help-description">{{ field.description }}</p>
                </template>
            </div>

            <input type="submit" name="send" value="Save" class="btn"/>
        </form>
    </div>
    <div v-else>
        <div class="spinner spinner-dark spinner-middle">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        action: {
            type: String,
            default: 'create'
        }
    },
    data: function () {
        return {
            integration: null,
            formdata: {},
            endpoints: [],
            fieldValues: {},
            error: null
        }
    },
    created: function () {
        this.$http.get('integration/' + this.$route.params.name).then((xhr) => {
            this.integration = xhr.data
            if (xhr.data.fields && xhr.data.fields.length > 0) {
                for (let i = 0; i < xhr.data.fields.length; i++) {
                    let field = xhr.data.fields[i];
                    if (field.phase === 'POST' && field.endpoint) {
                        this.endpoints.push(field.name)
                        this.fieldValues[field.name] = null
                        this.formdata[field.name] = ''
                    }
                }
            }

            if (this.integration.oauth && this.integration.editable) {
                this.reloadEndpoint(true)
            }
        })
    },
    computed: {
        processedFilters() {
            return this.integration.filters.filter((filter) => filter.phase === 'POST')
        }
    },
    methods: {
        reloadEndpoint: function (reload) {
            if (reload) {
                let endpointKey = this.integration.oauth && this.integration.editable ? 'account-endpoint' : 'endpoint'
                for (let i = 0; i < this.endpoints.length; i++) {
                    this.$http.post('integration/' + this.$route.params.name + '/' + endpointKey + '/' + this.endpoints[i], this.formdata).then((xhr) => {
                        if (xhr.data.result) {
                            this.fieldValues[this.endpoints[i]] = xhr.data.result

                            // This is required to force the refresh
                            this.fieldValues = JSON.parse(JSON.stringify(this.fieldValues))
                        }
                    })
                }
            }
        },
        save: function () {
            if (this.action === 'create') {
                this.formdata['integration'] = this.$route.params.name
                this.$http.post('integration/', this.formdata).then((xhr) => {
                    this.$router.push('/integrations/')
                }).catch((xhr) => {
                    this.error = xhr.data.error
                })
            } else {
                this.$http.put('integration/' + this.$route.params.name, this.formdata).then((xhr) => {
                    this.$router.push('/integrations/')
                }).catch((xhr) => {
                    this.error = xhr.data.error
                })
            }
            return false
        }
    }
}
</script>

<style scoped>

.btn-back {
    width: auto;
    display: inline-block;
    background: none;
    margin: 0;
}

.page-header p {
    margin-bottom: 15px;
}

.page-header a {
    margin-bottom: 30px;
}

.int-form {
    max-width: 650px;
    width: 100%;
    margin: 0 auto;
    padding: 25px;
    position: relative;
}

.int-form input, .int-form textarea, .int-form select {
    width: 100%;
    position: relative;
}

.int-form div input, .int-form div textarea, .int-form div select {
    width: 100%;
    position: relative;
    padding: 15px;
    height: auto !important;
    font-size: 13px;
    border: 1px solid #e2deda;
    margin-bottom: 5px;
}

.int-form div {
    margin-bottom: 20px;
}

.int-form div select {
    padding: 0;
    height: 48px !important;
}

.int-form div label {
    font-size: 13px;
    color: #80766e;
}

.int-form div label span {
    font-weight: lighter;
    opacity: .7;
}

</style>
