<template>
    <div class="login">
        <h1>{{ $t('auth.welcome_back') }}</h1>
        <form v-on:submit.prevent="onSubmit" ref="loginForm">
            <div>
                <i class="fa fa-user fa-2x"></i>
                <input type="email" name="email" v-model="email" v-bind:placeholder="$t('auth.your_email')" required/>
            </div>
            <div>
                <i class="fa fa-lock fa-2x"></i>
                <input type="password" name="password" v-model="password" v-bind:placeholder="$t('auth.your_password')"
                       required/>
            </div>
            <template v-if="loading">
                <div class="fakeSubmit">
                    <div class="spinner">
                        <div class="bounce1"></div>
                        <div class="bounce2"></div>
                        <div class="bounce3"></div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div>
                    <input type="submit" class="btn cta button-success" v-bind:value="$t('auth.login')"/>
                </div>
            </template>
        </form>

        <div class="option-panel">
            <router-link to="/auth/lost" class="u-pull-left">{{ $t('auth.lost_your_password') }}</router-link>
            <router-link to="/auth/resend" v-if="showSendVerifyLink">{{ $t('auth.resend_verification_link') }}
            </router-link>
            <router-link to="/auth/register" class="u-pull-right">{{ $t('auth.create_account') }}</router-link>
        </div>
    </div>
</template>

<script>
import Account from './../../store/account'
import Tooltips from './../misc/tooltips'
import {event} from '../../event.js'

export default {
    data: function () {
        return {
            email: window.storage.getItem('login-email', true),
            password: null,
            loggedOut: false,
            logoutReason: null,
            loading: false,
            showSendVerifyLink: false,
            randomImageId: Math.floor((Math.random() * 6) + 1)
        }
    },
    created: function () {
        if (window.document.location.hash.indexOf('loggedout') >= 0) {
            this.loggedOut = true
        }

        if (this.$route.query.result) {
            switch (this.$route.query.result) {
                case 'verification_problem':
                case 'verification_link_expired':
                case 'invalid_verify_link':
                    this.showSendVerifyLink = true
                case 'already_verified':
                    this.$alert.warning({message: this.$i18n.t('auth.' + this.$route.query.result), duration: 10000000})

                    break
                case 'verify_success':
                case 'successfully_registered':
                    this.$alert.info({message: this.$i18n.t('auth.' + this.$route.query.result), duration: 10000000})
                    break
                case 'no_bots':
                case 'stop_refresh_or_reset':
                    this.logoutReason = this.$route.query.result
                    break
                default:
                    break
            }
            this.$router.push({query: []})
        }
    },
    methods: {
        onSubmit: function () {
            let tooltips = Tooltips(this.$refs.loginForm)
            tooltips.clear()
            window.storage.setItem('login-email', this.email, true)

            this.error = null
            this.loading = true
            this.$http.post('internal/auth/login', {
                email: this.email,
                password: this.password
            }).then(
                (xhr) => {
                    if (xhr.data.token) {
                        Account.setModel(xhr.data.account)
                        event.emit('set-session', xhr.data.token)

                        if (this.$route.query.redirect) {
                            this.$router.push(this.$route.query.redirect)
                        } else {
                            this.$router.push('/dashboard')
                        }
                    } else {
                        this.loading = false
                        tooltips.error({'email': ['There is a problem with the login!']})
                    }
                },
                (response) => {
                    this.loading = false
                    tooltips.error(response.data)

                    return response
                }
            )
        },
        isProduction: function () {
            return window.location.hostname.indexOf('bitzfree.com') !== -1 || window.location.hostname.indexOf('elasticbeanstalk.com') !== -1
        }
    }
}
</script>

<style>
.fakeSubmit {
    height: 45px;
    background: #0ac4ac;
    border-radius: 3px;
    padding: 14px;
    letter-spacing: 1.5px;
    font-family: "brandon-grotesque", sans-serif;
    text-transform: uppercase;
    font-size: 11px;
    margin-bottom: 0;
}

.spinner {
    margin: 0 auto;
    width: 70px;
    text-align: center;
}

.spinner > div {
    width: 10px;
    height: 10px;
    background-color: #fff;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0)
    }
    40% {
        -webkit-transform: scale(1.0)
    }
}

@keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }
}
</style>
