<template>
    <div class="app-auth">
        <header id="app-header">
            <img src="/img/logo.svg" class="logo" height="40px"/>
        </header>
        <div class="device-xs visible-xs"></div>
        <div class="device-sm visible-sm"></div>
        <div class="device-md visible-md"></div>
        <div class="device-lg visible-lg"></div>

        <div class="print-logo hidden print-visible"><img alt="FraudRadar.io" width="250" src="/img/logo.svg">
        </div>

        <vue-alert></vue-alert>
        <div class="auth-wrapper">
            <div>
                <div class="auth-container">
                    <router-view></router-view>
                </div>
                <register-testimonials></register-testimonials>
            </div>
        </div>
    </div>
</template>


<script>
import '../../assets/css/login.css'
import '../../../public/css/landing-page/layout.css'
import RegisterTestimonials from './register-testimonials'

export default {
    components: {RegisterTestimonials}
}
</script>

<style>
.app-auth {
    height: 100%;
    background: #e6ecf0;
}

.app-auth form .fa {
    float: left;
    position: absolute;
    margin-top: 8px;
    margin-left: 10px;
}

.app-auth #app-header {
    background: #fff;
    text-align: center;
}

.app-auth #app-header .logo {
    margin-top: 5px;
}
</style>
