<template>
    <div>Welcome to FraudRadar Shopify plugin</div>
</template>

<script>
export default {
    name: 'home',
    created() {
    }
}
</script>

<style scoped>

</style>
