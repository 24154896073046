<template>
    <div class="settings-modal">
        <h3>Account Settings</h3>

        <div class="verify-panel" v-if="existingAccount.model && !existingAccount.model.verified">
            <div class="text-red">{{ $t('auth.not_verified') }}</div>
            <input type="button" @click="resend()" v-bind:value="$t('settings.resend')" class="btn">
        </div>

        <form v-on:submit.prevent="update" ref="form">
            <div class="subscription-container" v-if="existingAccount.model">
                <label>{{ $t('settings.your_plan') }}</label>
                <span class="plan-details" v-if="!existingAccount.model.stripe_plan_id">
                    <label>Free</label>
                    <router-link to="/subscribe/plans" title="You rock!" class="btn button-success cta"
                                 active-class="active" v-show="existingAccount.model && existingAccount.model.verified">
                        {{ $t('common.get_more_credits') }}
                    </router-link>
                </span>

                <span class="plan-details" v-if="existingAccount.model.stripe_plan_id">
                    <label class="plan">{{ existingAccount.model.stripe_plan_id.replace('plan_monthly_', '') }}</label>
                    <a @click="cancelPlan()" title="We are sorry to see you go!" class="btn button-cancel">
                        {{ $t('common.cancel_plan') }}
                    </a>
                    <router-link to="/subscribe/plans" title="You rock!" class="btn button-success"
                                 v-show="existingAccount.model.stripe_plan_id != 'plan_monthly_enterprise'">
                        {{ $t('common.get_more_credits') }}
                    </router-link>
                </span>
            </div>
            <div>
                <label>{{ $t('settings.email') }}</label>
                <input type="text" v-model="account.email" disabled/>
            </div>
            <div>
                <label>{{ $t('settings.old_password') }}</label>
                <input type="password" name="oldPassword" v-bind:placeholder="$t('settings.old_password_placeholder')"
                       v-model="oldPassword" v-on:keyup="resetBtnState"/>
            </div>
            <div>
                <label>{{ $t('settings.password') }}</label>
                <input type="password" name="password" pattern='.{8,64}' maxlength="64"
                       v-bind:placeholder="$t('settings.password_placeholder')" v-model="password"
                       v-on:keyup="resetBtnState"/>
            </div>
            <div>
                <label>{{ $t('settings.repassword') }}</label>
                <input type="password" name="repassword" pattern='.{8,64}' maxlength="64"
                       v-bind:placeholder="$t('settings.repassword_placeholder')" v-model="repassword"
                       v-on:keyup="resetBtnState"/>
            </div>
            <div class="button-container">
                <span style="margin-top: 5px">
                    <input type="submit" v-bind:value="$t('common.update')" class="btn" v-if="!saved"/>
                    <input type="button" v-bind:value="$t('common.saved')" class="btn" disabled v-if="saved">
                </span>
            </div>
        </form>
    </div>
</template>

<script>
import Account from './../../store/account'
import Tooltips from './../misc/tooltips'

export default {
    components: {},
    data: function () {
        return {
            saved: false,
            confirmDeleteAccount: false,
            invalidPassword: false,
            password: '',
            repassword: '',
            oldPassword: '',
            existingAccount: Account,
            account: {email: null, deleted: false}
        }
    },
    created: function () {
        if (!Account.model) {
            this.$watch(() => Account.model, (model) => {
                this.account.email = model.email
            })
            this.$watch(() => Account.session, (session) => {
                this.account.deleted = session.deleted
            })
        } else {
            this.account.email = Account.model.email
            this.account.deleted = Account.session.deleted
        }
    },
    methods: {
        update: function () {
            this.saved = true
            let form = Tooltips(this.$refs.form)
            form.clear()

            if (!this.oldPassword || this.oldPassword.length === 0) {
                form.error({oldPassword: [this.$i18n.t('settings.old_password_empty')]})
                return
            } else if (!this.password || this.password.length === 0) {
                form.error({password: [this.$i18n.t('settings.new_password_empty')]})
                return
            } else if (this.password !== this.repassword) {
                form.error({password: [this.$i18n.t('settings.passwords_dont_match')]})
                return
            }

            this.$http.post('internal/account/password', {
                oldPassword: this.oldPassword,
                newPassword: this.password
            }).then(
                (xhr) => {
                    this.$alert.info({message: this.$i18n.t('settings.successfully_updated')})
                },
                (xhr) => {
                    this.saved = false
                    form.error({btgAddress: [this.$i18n.t('settings.' + xhr.data.error)]})
                }
            )
        },
        resetBtnState: function () {
            this.saved = false
        },
        resend: function () {
            let form = Tooltips(this.$refs.form)
            form.clear()

            this.$http.post('internal/account/email/resend').then(
                (xhr) => {
                    this.$alert.info({message: this.$i18n.t('auth.successfully_resend')})
                    this.loading = false
                },
                (response) => {
                    this.loading = false
                    form.error(response.data)

                    return response
                }
            )
        },
        cancelPlan: function () {
            this.$http.post('internal/payment/cancel').then(
                (xhr) => {
                    this.$alert.info({message: 'Successfully canceled subscription!'})
                    Account.model.stripe_plan_id = null
                    Account.model.plan = null
                    this.loading = false
                },
                (response) => {
                    this.$alert.warning({message: 'Failed to cancel subscription!'})
                    this.loading = false

                    return response
                }
            )
        }
    }
}
</script>
<style>
.modal .settings-modal form > div.button-container {
    margin-top: 8px;
    border: none;
}

.modal .settings-modal .btn {
    margin-left: 15px;
    margin-top: 0px;
}

.modal .settings-modal .verify-panel {
    display: flex;
}

.subscription-container {
    margin: 20px 0px;
}

.subscription-container > div {
    display: flex;
}

.subscription-container .plan-details {
    width: 400px;
    display: inline-block;
    text-align: left;
}

.subscription-container .plan-details .btn {
    padding: 10px;
    float: right;
}

.subscription-container .plan {
    text-transform: capitalize;
    color: #364fcc;
    font-size: 20px;
}

.subscription-container .plan-details .btn {
    margin: 0px 5px;
    padding: 5px 10px;
    height: auto;
}
</style>
