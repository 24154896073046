<template>
    <div>

    </div>
</template>

<script>
export default {
    data() {
        return {}
    },
    created: function () {

    },
    ready: function () {

    },
    methods: {},
    events: {}
}
</script>
