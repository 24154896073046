<template>
    <div class="page-body whitelist-page">
        <div class="master-detail">
            <header>
                <span class="u-pull-left">
                    <h3 class="breadcrumb">Whitelist</h3>

                    <span class="list-count" v-show="totalCount !== null">{{ totalCount }} Total</span>
                </span>

                <span class="u-pull-right">
                    <input class="btn button-success" value="Add Whitelist" @click="addNew()">
                </span>
            </header>
            <div class="row">
                <div class="list" v-bind:class="{'col-md-9': showEditor, 'col-md-12': !showEditor}">
                    <ul>
                        <li class="list-header row">
                            <span class="col-xs-5">{{ $t('whitelist.content') }}</span>
                            <span class="col-xs-4">{{ $t('whitelist.type') }}</span>
                            <span class="col-xs-2">{{ $t('whitelist.created') }}</span>
                            <span class="col-xs-1">{{ $t('common.actions') }}</span>
                        </li>
                        <li class="row list-item" v-for="whitelist in entities" :key="whitelist.id">
                            <span class="col-xs-5">{{ whitelist.content }}</span>
                            <span class="col-xs-4">{{ whitelist.type }}</span>
                            <span class="col-xs-2">{{ whitelist.created }}</span>
                            <span class="col-xs-1 actions">
                                <a @click="editItem(whitelist)" v-bind:title="$t('common.edit')">
                                    <i class="fa fa-pencil"></i>
                                </a>
                                <a @click="remove(whitelist)" v-bind:title="$t('common.delete')">
                                    <i class="fa fa-trash"></i>
                                </a>
                            </span>
                        </li>
                        <infinite-loading @infinite="fetchList">
                            <div slot="no-more">The End...</div>
                        </infinite-loading>
                    </ul>
                </div>
                <div class="editor col-md-3" v-if="showEditor">
                    <h3 v-show="!editMode">Add Whitelist</h3>
                    <h3 v-show="editMode">Edit Whitelist</h3>
                    <form v-on:submit.prevent="save" ref="form">
                        <div>
                            <label>{{ $t('whitelist.content') }}</label>
                            <input type="text" v-model="entity.content"/>
                        </div>
                        <div>
                            <label>{{ $t('whitelist.type') }}</label>
                            <select type="text" v-model="entity.type">
                                <option value="" selected disabled class="select-empty">Type</option>
                                <option value="IP">IP</option>
                                <option value="EMAIL">Email</option>
                            </select>
                        </div>
                        <div class="button-container">
                            <input type="submit" v-bind:value="$t(editMode ? 'common.update' : 'common.save')"
                                   class="btn button-success"/>
                            <a class="cancel" @click="closeEditor()">{{ $t('common.cancel') }}</a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: function () {
        return {
            entities: null,
            entity: null,
            totalCount: null,
            loading: false,
            showEditor: false,
            editMode: false,
            addedEntityIds: [],
            limit: 20,
            offset: 0
        }
    },
    created: function () {

    },
    ready: function () {

    },
    methods: {
        fetchList: function ($state) {
            let withCount = false
            if (this.totalCount === null) {
                this.entities = []
                withCount = true
            }
            this.loading = true
            this.$http.get('whitelist?limit=' + this.limit + '&offset=' + this.entities.length + '&withCount=' + withCount).then(
                (xhr) => {
                    if (xhr.data) {
                        this.entities = this.entities.concat(xhr.data.result)
                        if (xhr.data.count !== null) {
                            this.totalCount = xhr.data.count
                        }

                        if (this.totalCount > this.entities.length) {
                            $state.loaded()
                        } else {
                            $state.complete()
                        }
                    }
                    this.loading = false
                },
                (xhr) => {
                    this.loading = false
                    $state.error()
                }
            )
        },
        addNew: function () {
            this.showEditor = true
            this.editMode = false

            this.entity = {
                content: null,
                type: 'IP'
            }
        },
        editItem: function (entity) {
            this.showEditor = true
            this.editMode = true
            this.entity = entity
        },
        closeEditor: function () {
            this.showEditor = false
            this.entity = null
        },
        save: function () {
            if (this.editMode) {
                this.$http.put('whitelist/' + this.entity.id, this.entity, {emulateJSON: false}).then(
                    (xhr) => {
                        this.entity = xhr.data
                        this.closeEditor()
                        this.$alert.info({message: this.$i18n.t('common.save_success')})
                    },
                    (xhr) => {
                        this.loading = false
                        this.$alert.danger({message: this.$i18n.t('common.save_failed')})
                    }
                )
            } else {
                this.$http.post('whitelist', this.entity, {emulateJSON: false}).then(
                    (xhr) => {
                        this.entity = xhr.data
                        this.entities.push(this.entity)

                        // Will be used to calculate offset as we add this item at local, we should not fetch it again
                        this.addedEntityIds.push(this.entity.id)
                        this.totalCount++
                        this.closeEditor()
                        this.$alert.info({message: this.$i18n.t('common.save_success')})
                    },
                    (xhr) => {
                        this.loading = false
                        this.$alert.danger({message: this.$i18n.t('common.save_failed')})
                    }
                )
            }
        },
        remove: function (entity) {
            var self = this
            this.$swal({
                title: 'Delete Whitelist',
                text: 'Are your sure to delete whitelist ' + entity.content + '?',
                type: 'warning',
                showCancelButton: true
            }).then(function (isConfirmed) {
                if (isConfirmed) {
                    self.$http.delete('whitelist/' + entity.id).then(
                        (xhr) => {
                            self.entities.splice(self.entities.indexOf(entity), 1)
                            self.totalCount--
                        },
                        (xhr) => {
                            // TODO alert
                            self.loading = false
                        }
                    )
                }
            })
        }
    },
    events: {}
}
</script>
