<template>
    <div>Unable to authorize plugin, please try again</div>
</template>

<script>
export default {
    data() {
        return {}
    },
    created: function () {
    },
    methods: {}
}
</script>
