<template>
    <nav id="app-navigation">
        <div class="container">
            <div class="logo">
                <img src="/img/logo.svg">
            </div>
            <router-link to="/subscribe/plans" title="You rock!" class="btn button-success cta" active-class="active"
                         v-if="account && account.model && !account.model.stripe_plan_id">
                <i class="fa fa-angle-double-up"></i>
                Upgrade Plan
            </router-link>
            <div class="nav-content">
                <ul class="top">
                    <li :class="{ 'active': hasRoute('/dashboard') }">
                        <router-link to="/dashboard" active-class="active" class="row">
                            <span class="col-md-2"><i class="fa fa-line-chart"></i></span>
                            <span class="col-md-10">{{ $t('menu.dashboard') }}</span>
                        </router-link>
                    </li>
                    <li class="menu" style="cursor: auto">
                        <span class="col-md-10">{{ $t('common.menu') }}</span>
                    </li>
                    <li :class="{ 'active': hasRoute('/rules') }">
                        <router-link to="/rules" active-class="active" class="row">
                            <span class="col-md-2"><i class="fa fa-cubes"></i></span>
                            <span class="col-md-10">{{ $t('menu.rules') }}</span>
                        </router-link>
                    </li>
                    <li :class="{ 'active': hasRoute('/whitelist') }">
                        <router-link to="/whitelist" active-class="active" class="row">
                            <span class="col-md-2"><i class="fa fa-pencil-square-o"></i></span>
                            <span class="col-md-10">{{ $t('menu.whitelist') }}</span>
                        </router-link>
                    </li>
                    <li :class="{ 'active': hasRoute('/reports') }" v-if="false">
                        <router-link to="/reports" active-class="active" class="row">
                            <span class="col-md-2"><i class="fa fa-file-text-o"></i></span>
                            <span class="col-md-10">{{ $t('menu.reports') }}</span>
                        </router-link>
                    </li>
                    <li :class="{ 'active': hasRoute('/integrations') }">
                        <router-link to="/integrations" active-class="active" class="row">
                            <span class="col-md-2"><i class="fa fa-puzzle-piece"></i></span>
                            <span class="col-md-10">{{ $t('menu.integrations') }}</span>
                        </router-link>
                    </li>
                </ul>
                <ul class="bottom">
                    <li class="menu-contact" :class="{ 'active': hasRoute('/contact-us') }">
                        <a href="mailto:support@fraudradar.io" active-class="active">
                            <span class="col-md-2"><i class="fa fa-envelope"></i></span>
                            <span class="col-md-10">{{ $t('menu.contact_us') }}</span>
                        </a>
                    </li>
                    <li :class="{ 'active': hasRoute('/settings/account') }">
                        <router-link to="/settings/account" active-class="active" class="row">
                            <span class="col-md-2"><i class="fa fa-cogs"></i></span>
                            <span class="col-md-10">{{ $t('menu.settings') }}</span>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
import './../assets/css/menu.css'
import Account from './../store/account'

export default {
    data() {
        return {
            account: Account
        }
    },
    methods: {
        hasRoute: function (partial) {
            return (this.$route.path.indexOf(partial) > -1)
        }
    }
}
</script>

<style>

</style>
