export default {
    'account': 'Account',
    'menu': 'Menu',
    'back': 'Back',
    'loading': 'Loading',
    'plan': 'Plan',
    'no_plan': 'Free',
    'free': 'Free',
    'no_credits': 'No credits left',
    'get_more_credits': 'Get More Credits',
    'cancel_plan': 'Cancel Plan',
    'copied_to_clipboard': 'Copied text to clipboard',
    'no_plan_description': 'You are on a free plan. Do you want to earn more with a little investment?',
    'upgrade_plan': 'You have a plan that is good, but do you want to earn more?',
    'subscribe_to_a_plan': 'Subscribe to a Plan',
    'actions': 'Actions',
    'edit': 'Edit',
    'delete': 'Delete',
    'update': 'Update',
    'save': 'Save',
    'saved': 'Saved!',
    'cancel': 'Cancel',
    'save_success': 'Successfully saved!',
    'save_failed': 'Failed to save, please try again!',
    'update_success': 'Successfully updated!',
    'update_failed': 'Failed to update, please try again!',
    'delete_success': 'Successfully deleted!',
    'delete_failed': 'Failed to delete, please try again!'
}
