'use strict'

import { event } from './event.js'

import authMain from './components/auth/index.vue'
import loginAuth from './components/auth/login.vue'
import lostAuth from './components/auth/lost.vue'
import lostRecoverPassword from './components/auth/recover-password.vue'
import registerAuth from './components/auth/register.vue'
import resendAuth from './components/auth/resend.vue'
import validateAuth from './components/auth/validate.vue'
import deletedAuth from './components/auth/deleted.vue'

import appMain from './components/app/index.vue'
import appDashboard from './components/app/dashboard.vue'
import appRules from './components/app/rules.vue'
import appWhitelist from './components/app/whitelist.vue'
import appReports from './components/app/reports.vue'
import appSettings from './components/settings/modal.vue'
import appSettingsAccount from './components/settings/account.vue'
import appSettingsBilling from './components/settings/billing.vue'
import appSettingsCreditCard from './components/settings/credit-card.vue'

import appSubscribeIndex from './components/subscribe/index.vue'
import appSubscribePlans from './components/subscribe/plans.vue'
import appSubscribePay from './components/subscribe/pay.vue'
import appSubscribeSuccess from './components/subscribe/success.vue'

import appIntegrationIndex from './components/integration/index'
import appIntegrationForm from './components/integration/form'
import appIntegrationList from './components/integration/list'
import appIntegrationOAuthError from './components/integration/oauth-error'
import appIntegrationOAuthSuccess from './components/integration/oauth-success'

import appPluginsIndex from './components/plugins/index'
import appPluginsShopifyIndex from './components/plugins/shopify/index'
import appPluginsShopifyHome from './components/plugins/shopify/home'

import Account from './store/account'

const requireAuth = function (to, from, next) {
    if (!window.storage.getItem('session') && to.fullPath !== '/auth/login') {
        if (to.fullPath === '/') {
            next({ path: '/auth/login' })
        } else {
            next({ path: '/auth/login', query: { redirect: to.fullPath } })
        }
    } else {
        if (Account && Account.model) {
            Account.applyScreenChange()
        }
        next()
    }
}

export default {
    routes: [{
        path: '',
        component: appMain,
        beforeEnter: requireAuth,
        children: [{
            path: 'settings',
            name: 'settings',
            component: appSettings,
            beforeEnter: requireAuth,
            children: [{
                path: 'account',
                name: 'settings-account',
                component: appSettingsAccount,
                beforeEnter: requireAuth
            }, {
                path: 'billing',
                name: 'settings-billing',
                component: appSettingsBilling,
                beforeEnter: requireAuth
            }, {
                path: 'credit-card',
                name: 'settings-cc',
                component: appSettingsCreditCard,
                beforeEnter: requireAuth
            }]
        }, {
            path: 'integrations',
            component: appIntegrationIndex,
            beforeEnter: requireAuth,
            children: [{
                path: '/',
                name: 'integration-list',
                component: appIntegrationList
            }, {
                path: ':name/add',
                name: 'integration-add',
                component: appIntegrationForm,
                props: { action: 'create' }
            }, {
                path: ':name/edit',
                name: 'integration-edit',
                component: appIntegrationForm,
                props: { action: 'update' }
            }, {
                path: ':name/success',
                name: 'integration-oauth-success',
                component: appIntegrationOAuthSuccess
            }, {
                path: ':name/error',
                name: 'integration-oauth-error',
                component: appIntegrationOAuthError
            }]
        }, {
            path: 'dashboard',
            name: 'dashboard',
            component: appDashboard,
            beforeEnter: requireAuth
        }, {
            path: 'rules',
            name: 'rules',
            component: appRules,
            beforeEnter: requireAuth
        }, {
            path: 'whitelist',
            name: 'whitelist',
            component: appWhitelist,
            beforeEnter: requireAuth
        }, {
            path: 'reports',
            name: 'reports',
            component: appReports,
            beforeEnter: requireAuth
        }]
    }, {
        path: '',
        component: authMain,
        children: [{
            path: 'validate/token/:token',
            component: validateAuth
        }]
    }, {
        path: '',
        component: appSubscribeIndex,
        beforeEnter: requireAuth,
        children: [{
            path: 'subscribe/plans',
            name: 'subscribe-plans',
            component: appSubscribePlans,
            beforeEnter: requireAuth
        }, {
            path: 'subscribe/:planId/pay',
            name: 'subscribe-pay',
            component: appSubscribePay,
            beforeEnter: requireAuth
        }, {
            path: 'subscribe/:planId/success',
            name: 'subscribe-success',
            component: appSubscribeSuccess,
            beforeEnter: requireAuth
        }]
    }, {
        path: '/auth',
        component:
        authMain,
        children: [{
            path: 'login',
            component: loginAuth
        }, {
            path: 'token/:token',
            name: 'auth-token',
            component: {
                created: function () {
                    event.emit('set-session', this.$route.params.token)
                    this.$router.push('/dashboard')
                }
            }
        }, {
            path: 'lost',
            component: lostAuth
        }, {
            path: 'recover-password',
            component: lostRecoverPassword
        }, {
            path: 'deleted',
            component: deletedAuth
        }, {
            path: 'register',
            component: registerAuth
        }, {
            path: 'resend',
            component: resendAuth
        }]
    }, {
        path: '/plugins',
        component: appPluginsIndex,
        children: [{
            path: 'shopify',
            component: appPluginsShopifyIndex,
            children: [{
                path: '/',
                component: appPluginsShopifyHome
            }, {
                path: '/error',
                component: appIntegrationOAuthError
            }]
        }]
    }]
}
