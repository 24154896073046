<template>
    <div>
        <p v-show="ref" class="margin-top-10"><i class="fa fa-check"></i>You are using the referral link of user
            {{ ref }}</p>
        <form v-on:submit.prevent="onSubmit">
            <div>
                <i class="fa fa-user fa-2x"></i>
                <input type="text" v-model="name" :placeholder="$t('auth.your_name')" required/>
            </div>
            <div>
                <i class="fa fa-envelope fa-2x"></i>
                <input type="email" v-model="email" :placeholder="$t('auth.your_email')" required/>
            </div>
            <div>
                <i class="fa fa-lock fa-2x"></i>
                <input type="password" v-model="password" :placeholder="$t('auth.your_password')" required/>
            </div>
            <div class="non-human-panel">
                <div class="u-pull-left">
                    <div ref="recaptchaEl" name="g-recaptcha" class="g-recaptcha"
                         data-sitekey="6LcDz9MUAAAAACq3gZiC2Ju5_G7revDL2fdkJp1T" required></div>
                </div>
            </div>
            <template v-if="loading">
                <div class="fakeSubmit">
                    <div class="spinner">
                        <div class="bounce1"></div>
                        <div class="bounce2"></div>
                        <div class="bounce3"></div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div>
                    <input type="submit" class="btn cta button-success" v-bind:value="$t('auth.signup')"/>
                </div>
                <br>
                <p class="suggest-login text-center">Already have an account?
                    <router-link to="/auth/login">Login</router-link>
                </p>
            </template>
        </form>

        <div class="option-panel">
            By signing up, you agree to our <a
            href="https://www.fraudradar.io/terms-of-services/" target="_blank">Terms of Service</a> and <a
            href="https://www.fraudradar.io/privacy-policy-2/" target="_blank">Privacy Policy</a>
        </div>
    </div>
</template>


<script>
/* global grecaptcha, $ */

import Tooltips from './../misc/tooltips'
import Account from './../../store/account'
import {event} from '../../event.js'

export default {
    data: function () {
        return {
            email: null,
            password: null,
            name: null,
            loading: false,
            showTooltip: false,
            conversionId: null,
            ref: window.storage.getItem('ref', true) || this.$route.query.ref,
            randomImageId: Math.floor((Math.random() * 6) + 1)
        }
    },
    created: function () {
        if ('tap' in window) {
            window.tap('conversion', null, 0, {}, null, (conversion) => {
                this.conversionId = conversion.id
            })
        }
        $(document.body).addClass('register')
    },
    destroyed() {
        $(document.body).removeClass('register')
    },
    mounted: function () {
        if (this.isProduction()) {
            grecaptcha.render(this.$refs.recaptchaEl)
        }
    },
    methods: {
        mouseOver: function () {
            this.showTooltip = true
        },
        mouseOut: function () {
            this.showTooltip = false
        },
        onSubmit: function () {
            let tooltips = Tooltips(document.querySelector('form'))
            tooltips.clear()
            let captchaText = null
            if (this.isProduction()) {
                let recaptchaResponse = document.getElementsByName('g-recaptcha-response')[0]
                captchaText = recaptchaResponse.value
                if (captchaText.length === 0) {
                    tooltips.error({'g-recaptcha': ['Please fill recaptcha!']})
                    return
                }
            }

            this.error = null
            this.loading = true
            let params = {
                email: this.email,
                password: this.password,
                name: this.name,
                captcha: captchaText || '-'
            }

            this.$http.post('internal/account/', params).then(
                (xhr) => {
                    Account.setModel(xhr.data.account)
                    event.emit('set-session', xhr.data.token)

                    if (this.$route.query.redirect) {
                        this.$router.push(this.$route.query.redirect)
                    } else {
                        this.$router.push('/dashboard')
                    }

                    window.sessionStorage.removeItem('ref')
                    this.loading = false
                },
                (response) => {
                    this.loading = false
                    tooltips.error(response.data)
                    if (this.isProduction()) {
                        grecaptcha.reset()
                    }

                    return response
                }
            )
        },
        isProduction: function () {
            return window.location.hostname.indexOf('fraudradar.io') !== -1 || window.location.hostname.indexOf('elasticbeanstalk.com') !== -1
        }
    }
}
</script>

<style lang="scss">

p.em-c-explain {
    margin-top: 20px;
}

.em-c-back {
    width: 100%;
    text-align: center;
    display: inline-block;
    margin-top: 8px;
    border: 2px solid #e2e2e2;
    padding: 10px;
    border-radius: 3px;
}

.email {
    position: relative;
}

.email p {
    position: absolute;
    visibility: hidden;
    top: -76px;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    padding: 10px;
    width: 195px;
    right: -68px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transform: translateY(6px);
    opacity: 0;
    -webkit-transition: all .25s ease-in-out;
    -moz-transition: all .25s ease-in-out;
    -o-transition: all .25s ease-in-out;
    transition: all .25s ease-in-out;
}

.email.tt-open p {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
}

.email svg {
    position: absolute;
    right: 14px;
    top: 15px;
    opacity: 0.2;
    width: 12px;
    cursor: pointer;
    -webkit-transition: all .25s ease-in-out;
    -moz-transition: all .25s ease-in-out;
    -o-transition: all .25s ease-in-out;
    transition: all .25s ease-in-out;
}

.email svg:hover {
    opacity: 1;
}

body.register {
    #app .auth-wrapper {
        @media (min-width: 900px) {
            top: 20%;

            .register-testimonials {
                display: block;
                float: right;
                margin-top: 20px;
            }
        }

        > div {
            width: 800px;
            margin: 0 auto;

            .auth-container {
                @media (min-width: 900px) {
                    width: 450px;
                    float: left;
                    margin-top: 20px;
                    padding: 25px;

                    > div {
                        width: 100%;
                        padding: 0;
                    }

                    form {
                        margin-top: 0px;
                    }

                    input {
                        max-width: inherit;
                    }
                }

                .suggest-login {
                    font-size: 13px;
                    color: #222;

                    a {
                        color: #4B68AF;
                        font-size: 13px;
                    }
                }

                .option-panel {
                    padding: 10px 0px;
                    font-size: 11px;
                    text-align: center;

                    a {
                        color: #222;
                        font-size: 11px;
                        text-decoration: underline;
                    }
                }

                .fa-envelope {
                    font-size: 1.5em;
                    margin-top: 9px;
                }
            }
        }
    }
}
</style>
