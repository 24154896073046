<template>
    <router-view></router-view>
</template>

<script>
import {event} from './../../event.js'

export default {
    name: 'index',
    created() {
        if (this.$route.query.token) {
            event.emit('set-session', this.$route.query.token)
            this.$router.replace({query: null})
        }
    }
}
</script>

<style scoped>

</style>
